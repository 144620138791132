import React from "react";

import verticalIcon from "assets/images/icons/Vector(5).svg";
import { UpcomingScheduleCardWrapper } from "./styles/styled.upcomingScheduleCard";

const UpcomingScheduleCard = ({ item, colorObj, hasNoIcon }) => {
  const { color, bgColor } = colorObj;
  return (
    <UpcomingScheduleCardWrapper color={color} bgColor={bgColor}>
      <div className="time">{item?.meetingTime}</div>
      <div className="user">
        <div className="name">{item?.name}</div>
        <div className="email">{item?.email}</div>
      </div>
      {hasNoIcon ? (
        <div className="icon"></div>
      ) : (
        <div className="icon">
          <img src={verticalIcon} alt="" />
        </div>
      )}
    </UpcomingScheduleCardWrapper>
  );
};

export default UpcomingScheduleCard;
