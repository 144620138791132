import React from "react";
import { useMutation, useQueryClient } from "react-query";

import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { FlexBox } from "components/reusables/FlexBox";
import Button from "components/reusables/Button";
import { deleteDepartment } from "api/career";

import { DeleteCategoryWrapper } from "./styles/styled.deleteCategory";

const DeleteCategory = ({ close, selectedCategory, departments }) => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(deleteDepartment, {
    onSuccess: () => {
      queryClient.refetchQueries("get-open-roles");
      close();
      toastSuccess('"Category deleted successfully"');
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const handleDelete = () => {
    const findID = departments.find((item) => item.name === selectedCategory);
    deleteMutation.mutateAsync(findID?._id);
  };

  return (
    <DeleteCategoryWrapper>
      <p>Are you sure you want to delete {selectedCategory} category </p>
      <FlexBox direction="row" justifyContent="center" mb="1rem">
        <Button className="no" variant="contained" onClick={close}>
          No
        </Button>
        <Button
          className="yes"
          variant="contained"
          onClick={handleDelete}
          disabled={deleteMutation.isLoading}
        >
          {deleteMutation.isLoading ? "Loading..." : "Delete"}
        </Button>
      </FlexBox>
    </DeleteCategoryWrapper>
  );
};

export default DeleteCategory;
