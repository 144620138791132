import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const ScheduleWrapper = styledComponents.div`
margin : 55px 31px 0;
.heading{
    font-family : ${fonts.giloryMedium};
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
    margin-bottom : 0;
}
.weekly-heading{
  margin-top : 21px;
  border: 1px solid #EEF4FF;
  padding : 19px 15px;
  .title{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #050C12;
    font-family : ${fonts.gilorySemiBold};
    margin-bottom : 0;
  }
  svg{
    cursor: pointer;
  }
}
.week {
  span{
    font-weight: 700;
    font-size: 13px;
    line-height: 157%;
    color: #666666;
      font-family : ${fonts.giloryBold};
  }
}
.schedule-table{
  border: 1px solid #EEF4FF;
  padding-left  : 16px;
  padding-top : 24px;
}
.month-view{
  flex : 1;
}
.week-view{
  flex: 3;
}
  @media (max-width: 576px) {
    margin : 21px 5px 0;
.schedule-table{
  flex-direction : column;
}
.month-view{
 width : 100%;
}
.week-view{
 width : 100%;
 margin-top : 20px;
}
  }
`;
