import dayjs from "dayjs";
import { selectWeek } from "utils/helpers";
export const format = "YYYY-MM-DD";

export const calendarHeaderButtons = [
  {
    name: "Day",
    value: "day",
  },
  {
    name: "Week",
    value: "week",
  },
  {
    name: "Month",
    value: "month",
  },
];

export const dayTimelineData = ["10", "11", "12", "13", "14", "15", "16", "17"];

export const getDaySchedules = (schedules, day) => {
  const events = schedules?.filter(
    (evt) =>
      dayjs(evt.meetingDate).format("DD-MM-YY") === day.format("DD-MM-YY")
  );
  return events;
};

let currentTimeCount = 10;
export const date = new Date();
export const dateAndTimeArray = new Array(9).fill(9).map(() => {
  currentTimeCount++;
  return selectWeek(date).map((weekDay) => {
    return {
      time: `${currentTimeCount - 1}:00`,
      weekDay,
    };
  });
});

export const getSchedules = (schedules, dateObj) => {
  const date = dayjs(dateObj?.weekDay).format("YYYY-MM-DD");
  const event = schedules?.find(
    (item) => item.meetingDate === date && item.meetingTime === dateObj?.time
  );

  return event;
};
