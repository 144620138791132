import api from "api";

export function getMessages(key) {
  const { read, replied } = key.queryKey[1];
  return api
    .get(`/contact/getMessages?read=${read}&replied=${replied}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function markMessageAsRead(payload) {
  const { read = false, replied = false, id } = payload;
  let url = new URL("https://example.com");
  let params = new URLSearchParams(url.search);
  if (read) params.set("read", read);
  if (replied) params.set("replied", replied);
  const newUrl = params.toString();
  return api
    .put(`/contact/updateMessage/${id}/?${newUrl}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteMessage(id) {
  return api
    .delete(`/contact/delete/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
