import styledComponents from "styled-components";
import { FlexBox } from "components/reusables/FlexBox";

export const DayWrapper = styledComponents(FlexBox)`
gap : 20px;
.month{
    flex : 1;
}
.timeline{
    flex : 3;
    position: relative;
}
.red_line{
border: 1px solid #E31D1C;
position: absolute;
width : 95%;
    right: 0;
}

 @media (max-width: 576px) {
       flex-direction : column-reverse;
       .month{
         width : 100%;
}
.timeline{
         width : 100%;
}
 }

`;
