import React from "react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";

import { FlexBox } from "components/reusables/FlexBox";
import CustomInputBox from "./Input";
import Button from "components/reusables/Button";
import { VALIDATION_SCHEMA_ADD_OPENING } from "./validation";
import { createOpenRoles } from "api/career";
import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { nature, jobType, ADD_NEW_OPENING_INITAL_VALUES } from "./data";

import { AddNewOpeningWrapper } from "./styles/styled.addnewopening";

const AddNewOpening = ({ close, data }) => {
  const queryClient = useQueryClient();
  const createOpeningMutation = useMutation(createOpenRoles, {
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
    onSuccess: () => {
      queryClient.refetchQueries("get-open-roles");
      close();
      toastSuccess("Role created successfully");
    },
  });
  const formik = useFormik({
    initialValues: ADD_NEW_OPENING_INITAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA_ADD_OPENING,
  });

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  function handleSubmit(values) {
    const payload = {
      ...values,
      responsibility: values.responsibility.split(","),
      coreSkills: values.coreSkills.split(","),
      managementSkills: values.managementSkills.split(","),
      desiredSkills: values.desiredSkills.split(","),
      //   aboutRole: values.aboutRole.split(","),
    };
    createOpeningMutation.mutateAsync(payload);
  }

  return (
    <AddNewOpeningWrapper>
      <h1>Add New Opening</h1>
      <CustomInputBox
        name="title"
        label="New Job Opening *"
        placeholder={"Type here"}
        value={formik.values.title}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("title")}
        errorMessage={formik.errors.title}
      />
      <CustomInputBox
        name="summary"
        label="Enter Job summary *"
        value={formik.values.summary}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("summary")}
        errorMessage={formik.errors.summary}
      />

      <FlexBox align="flex-start" className="w-75">
        <div>
          <p className="heading-2">Select Category *</p>
          {data?.data.map((item) => (
            <div class="radio-input" key={item.name}>
              <input
                type="radio"
                name={"department"}
                id={item.name}
                value={item.name}
                onChange={formik.handleChange}
                checked={formik.values.department === item.name}
                className={
                  formik.values.department === item.name
                    ? "active-border radio"
                    : "radio"
                }
              />
              <label class="radio-label" htmlFor={item.name}>
                {item.name}
              </label>
            </div>
          ))}
          {hasErrors("department") && (
            <small className="input-text-error">
              {formik.errors.department}
            </small>
          )}
        </div>
        <div>
          <p className="heading-2">Select Nature *</p>
          {nature.map((item) => (
            <div class="radio-input" key={item.value}>
              <input
                type="radio"
                name={"nature"}
                id={item.label}
                value={item.value}
                onChange={formik.handleChange}
                checked={formik.values.nature === item.value}
                className={
                  formik.values.nature === item.value
                    ? "active-border radio"
                    : "radio"
                }
              />
              <label class="radio-label" htmlFor={item.label}>
                {item.label}
              </label>
            </div>
          ))}
          {hasErrors("nature") && (
            <small className="input-text-error">{formik.errors.nature}</small>
          )}
        </div>
        <div>
          <p className="heading-2">Job Type *</p>
          {jobType.map((item) => (
            <div class="radio-input" key={item.value}>
              <input
                type="radio"
                name={"type"}
                id={item.label}
                value={item.value}
                onChange={formik.handleChange}
                checked={formik.values.type === item.value}
                className={
                  formik.values.type === item.value
                    ? "active-border radio"
                    : "radio"
                }
              />
              <label class="radio-label" htmlFor={item.label}>
                {item.label}
              </label>
            </div>
          ))}
          {hasErrors("type") && (
            <small className="input-text-error">{formik.errors.type}</small>
          )}
        </div>
      </FlexBox>
      <CustomInputBox
        name="about"
        label="About Blusalt*"
        isTextarea
        value={formik.values.about}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("about")}
        errorMessage={formik.errors.about}
      />
      {/* <CustomInputBox
        name="aboutRole"
        label="About Role *"
        isTextarea
        value={formik.values.aboutRole}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("aboutRole")}
        errorMessage={formik.errors.aboutRole}
      /> */}
      <CustomInputBox
        name="responsibility"
        label="Responsibilities (Separate list by commas) *"
        isTextarea
        value={formik.values.responsibility}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("responsibility")}
        errorMessage={formik.errors.responsibility}
      />
      <CustomInputBox
        name="coreSkills"
        label="Core Skills (Separate list by commas) *"
        isTextarea
        value={formik.values.coreSkills}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("coreSkills")}
        errorMessage={formik.errors.coreSkills}
      />
      <CustomInputBox
        name="managementSkills"
        label="Management Skills (Separate list by commas)"
        isTextarea
        value={formik.values.managementSkills}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("managementSkills")}
        errorMessage={formik.errors.managementSkills}
      />
      <CustomInputBox
        name="desiredSkills"
        label="Desired skills, competencies, and expertise (Separate list by commas)"
        isTextarea
        value={formik.values.desiredSkills}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("desiredSkills")}
        errorMessage={formik.errors.desiredSkills}
      />
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        disabled={createOpeningMutation.isLoading || !formik.isValid}
      >
        {createOpeningMutation.isLoading ? "Loading.." : "Add Opening"}
      </Button>
    </AddNewOpeningWrapper>
  );
};

export default AddNewOpening;
