import React, { useState } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";

import { getWeekSchedule } from "api/calendar";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import { getRandomColor, selectWeek } from "utils/helpers";
import { dateAndTimeArray, date, getSchedules } from "../data";

import { WeekWrapper } from "../styles/styled.week";

const Week = () => {
  const [currentWeek] = useState(selectWeek(date));
  const firstDate = dayjs(currentWeek[0]).format("YYYY-MM-DD");
  const lastDate = dayjs(currentWeek[6]).format("YYYY-MM-DD");

  const { isLoading, data } = useQuery(
    [
      "get-week-schedule",
      {
        firstDate,
        lastDate,
      },
    ],
    getWeekSchedule
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <WeekWrapper>
      <table className="mb-2">
        <thead>
          <tr className="thead-row">
            {selectWeek(date).map((day, i) => (
              <th key={i} scope="col">
                <span className="d-block text-center weekdays-title">
                  {dayjs(day).format("ddd, MMM DD")}
                </span>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <table>
        <tbody>
          {dateAndTimeArray.map((row, i) => (
            <tr key={i} className="thead-row">
              <p className="time">{i + 10}:00</p>
              {row.map((day) => (
                <td className="" onClick={() => getSchedules(data?.data, day)}>
                  {getSchedules(data?.data, day)?.name && (
                    <div
                      className="event"
                      style={{
                        backgroundColor: getRandomColor()?.bgColor,
                      }}
                    >
                      <p className="name">
                        {getSchedules(data?.data, day)?.name}
                      </p>
                      <p className="email">
                        {getSchedules(data?.data, day)?.email}
                      </p>
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </WeekWrapper>
  );
};

export default Week;
