import axios from "axios";

export function login(payload) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/authentication/signIn`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
