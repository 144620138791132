import React from "react";

import { ReactComponent as RedPoint } from "assets/images/icons/Ellipse2.svg";
import { ReactComponent as BluePoint } from "assets/images/icons/Ellipse2(1).svg";

import { EventsWrapper } from "../styles/styled.monthEvents";

const Events = ({ event, isCurrentDay }) => {
  return (
    <EventsWrapper>
      <p
        style={{
          background: isCurrentDay !== "" ? "#EAFAFF" : "",
          borderRadius: isCurrentDay !== "" ? "2px" : "",
          color: isCurrentDay !== "" ? "#666666" : "#101E2D",
        }}
      >
        {isCurrentDay ? <BluePoint /> : <RedPoint />} {event?.name}
      </p>
    </EventsWrapper>
  );
};

export default Events;
