import React from "react";

import { FlexBox } from "components/reusables/FlexBox";

import { DayTimelineWrapper } from "../styles/styles.dayTimeline";

const DayTimeline = ({ time, item, colorObj, hasEvent }) => {
  const { color, bgColor } = colorObj;

  return (
    <DayTimelineWrapper align="flex-start" color={color} bgColor={bgColor}>
      <div className="time">{time}</div>

      <div className="left">
        <div className="line" />
        {hasEvent && (
          <FlexBox className="card-c">
            <div className="user">
              <div className="name">{item?.name}</div>
              <div className="email">{item?.email}</div>
            </div>
            <div className="icon"></div>
          </FlexBox>
        )}
      </div>
    </DayTimelineWrapper>
  );
};

export default DayTimeline;
