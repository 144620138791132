import styledComponents from "styled-components";
import { CardOverlay } from "components/reusables/FlexBox";

export const UserWrapper = styledComponents(CardOverlay)`
margin-top : 11px;
height: 299px;
flex-direction : column;
align-items : center;
justify-content : center;
.name{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #000000;
    margin : 5px 0;
}
    .email{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #979797;
    }
`;
