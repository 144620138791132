import React from "react";

import { getRandomColor } from "utils/helpers";
import UpcomingScheduleCard from "./UpcomingScheduleCard";
import EmptyData from "utils/EmptyData/EmptyData";
import { UpcomingScheduleWrapper } from "./styles/styled.upcomingSchedule";

const UpcomingSchedule = ({ schedules }) => {
  return (
    <UpcomingScheduleWrapper>
      <h2>Upcoming Schedule</h2>
      {schedules?.length === 0 ? (
        <EmptyData text="No Upcoming Schedules" />
      ) : (
        schedules?.map((item) => {
          return (
            <UpcomingScheduleCard
              key={item._id}
              item={item}
              colorObj={getRandomColor()}
              hasNoIcon
            />
          );
        })
      )}
    </UpcomingScheduleWrapper>
  );
};

export default UpcomingSchedule;
