export const nature = [
  {
    name: "category",
    value: "full time",
    label: "Full-time",
  },
  {
    name: "",
    value: "part time",
    label: "Part-time",
  },
  {
    name: "",
    value: "contract",
    label: "Contract",
  },
];

export const jobType = [
  {
    name: "",
    value: "remote",
    label: "Remote",
  },
  {
    name: "",
    value: "in-house",
    label: "In-House",
  },
  {
    name: "",
    value: "hybrid",
    label: "Hybrid",
  },
];

export const ADD_NEW_OPENING_INITAL_VALUES = {
  department: "",
  title: "",
  summary: "",
  nature: "",
  type: "",
  about: "",
  responsibility: "",
  coreSkills: "",
  managementSkills: "",
  desiredSkills: "",
  //   aboutRole: "",
};
