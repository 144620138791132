import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
`;

export const CardOverlay = styled.div`
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 4px 9px rgba(29, 91, 154, 0.08);
  padding: 20px;
`;
