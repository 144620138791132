import React from "react";

import dayjs from "dayjs";

import { fonts } from "components/constants";
import Button from "components/reusables/Button";

import { CalendarHeaderWrapper } from "./styles/styled.calendarHeader";
import { calendarHeaderButtons } from "./data";

const CalendarHeader = ({ active, toggleActiveChange, daySelected }) => {
  return (
    <CalendarHeaderWrapper>
      <div className="todays_date">
        {dayjs(daySelected).format("dddd, DD MMMM YYYY")}
      </div>
      <div className="state_toggler">
        {calendarHeaderButtons.map(({ name, value }) => (
          <Button
            background={active !== value ? "#FAFBFD" : ""}
            color={active !== value ? "#89A9C4" : ""}
            padding="0 20.5px"
            fs="14px"
            ff={active === value ? fonts.giloryMedium : fonts.giloryRegular}
            onClick={() => toggleActiveChange(value)}
            key={value}
          >
            {name}
          </Button>
        ))}
      </div>
      <div className="day_switch"></div>
    </CalendarHeaderWrapper>
  );
};

export default CalendarHeader;
