import React, { useEffect } from "react";
import $ from "jquery";
import ScrollToTop from "utils/ScrollToTop/ScrollToTop";
import SubRoutes from "./common/routes/subRoutes";
import Sidebar from "./common/Sidebar/Sidebar";
import "./Dashboard.scss";

const Dashboard = () => {
  useEffect(() => {
    $("#sidebar-toggler").on("click", function () {
      $(".sidebar").toggleClass("active");
      $(".main-content").toggleClass("active");
    });

    $("#nav-toggler, .dash__content, .sidebar").on("click", function () {
      $(".sidebar").removeClass("active");
      $(".main-content").removeClass("active");
    });
  }, []);

  return (
    <div className="dashbase">
      <Sidebar />
      <div className="main-content min-vh-100">
        <div className="flex__end d-lg-none d-flex">
          <button
            className="navbar-toggler collapsed"
            type="button"
            id="sidebar-toggler"
          >
            <span> </span>
            <span> </span>
            <span className="ms-auto"> </span>
          </button>
        </div>
        <div className="dash__content">
          <ScrollToTop />
          <SubRoutes />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
