import React from "react";
import { NotificationsWrapper } from "./styles/styled.notifications";
import EmptyData from "utils/EmptyData/EmptyData";

const Notifications = ({ data }) => {
  return (
    <NotificationsWrapper>
      <h1 className="heading">Notifications</h1>
      <div className="noitifications">
        {data?.length === 0 ? (
          <EmptyData text="No notifications" />
        ) : (
          data?.map((item, index) => (
            <div className="notification" key={index}>
              <div className="message">{item?.notification?.text}</div>
              <div className="sender">by {item?.notification?.sender}</div>
            </div>
          ))
        )}
      </div>
    </NotificationsWrapper>
  );
};

export default Notifications;
