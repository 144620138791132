import api from "api";
export function getTodaysSchedule() {
  return api
    .get(`/booking/getDaySchedule/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getMonthsSchedule() {
  return api
    .get(`/booking/getMonthSchedule/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAvailability() {
  return api
    .get(`/booking/getAvailability/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getWeekSchedule(key) {
  const { firstDate, lastDate } = key.queryKey[1];
  return api
    .get(`/booking/getWeekSchedule/?firstDay=${firstDate}&lastDay=${lastDate}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createAvailability(payload) {
  return api
    .post(`/booking/setAvailability/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeAvailability(payload) {
  return api
    .post(`/booking/removeAvailability/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
