import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { login } from "api/auth";
import { FlexBox } from "components/reusables/FlexBox";
import { storeUserData } from "storage/sessionStorage";
import { hasErrors, toastError } from "utils/helpers";
import AuthLayout from "../AuthLayout";
import CustomPassword from "utils/CustomInputBox/CustomPassword";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { useFormik } from "formik";
import { LOGIN_VALIDATION_SCHEMA, initialValues } from "./utils";

const Login = () => {
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleClick,
		validationSchema: LOGIN_VALIDATION_SCHEMA,
	});

	const loginMutation = useMutation(login, {
		onSuccess: (res) => {
			localStorage.setItem("role", res.data?.role);
			storeUserData(res.data?.token);
			history.push("/");
		},
		onError: (err) => {
			let error = err?.response?.data?.message;
			toastError(error);
		},
	});

	const history = useHistory();

	function handleClick(values) {
		const { email, password } = values;
		const payload = {
			email,
			password,
		};
		localStorage.clear();
		loginMutation.mutateAsync(payload);
	}

	return (
		<AuthLayout>
			<FlexBox className="greeting">
				<p>
					Hello, <span>Admin</span>
				</p>
				{/* <Link to="/forgot-password" className="forgot-password">
          Forgot Password?
        </Link> */}
			</FlexBox>
			<form onSubmit={formik?.handleSubmit}>
				<CustomInputBox
					type="email"
					placeholder="@blusalt.net"
					name="email"
					label="Email Address"
					value={formik.values.email}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					error={hasErrors(formik, "email")}
				/>

				<CustomPassword
					label="Password"
					name="password"
					placeholder="Enter your password"
					value={formik?.values?.password}
					onBlur={formik?.handleBlur}
					onChange={formik?.handleChange}
					error={hasErrors(formik, "password")}
				/>

				<button type="submit" disabled={loginMutation.isLoading}>
					{loginMutation.isLoading ? "Loading..." : "Login"}
				</button>
			</form>
		</AuthLayout>
	);
};

export default Login;
