import React from "react";

import { useMutation, useQueryClient } from "react-query";

import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { FlexBox } from "components/reusables/FlexBox";
import Button from "components/reusables/Button";
import { deleteMessage } from "api/messaging";

import { DeleteMessageWrapper } from "./styles/styled.deleteSingleMessage";

const DeleteMessage = ({ close, id }) => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(deleteMessage, {
    onSuccess: () => {
      queryClient.refetchQueries("get-message");
      close();
      toastSuccess("Message deleted successfully");
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const handleDelete = () => {
    deleteMutation.mutateAsync(id);
  };

  return (
    <DeleteMessageWrapper>
      <p>Are you sure you want to delete this message </p>
      <FlexBox
        direction="row"
        justifyContent="center"
        mb="1rem"
        className="cta-buttons"
      >
        <Button className="no" variant="contained" onClick={close}>
          No
        </Button>
        <Button
          className="yes"
          variant="contained"
          onClick={handleDelete}
          disabled={deleteMutation.isLoading}
        >
          {deleteMutation.isLoading ? "Loading..." : "Delete"}
        </Button>
      </FlexBox>
    </DeleteMessageWrapper>
  );
};

export default DeleteMessage;
