import styledComponents from "styled-components";

export const AddCategoryWrapper = styledComponents.div`
h1{
font-weight: 400;
font-size: 26px;
line-height: 31px;
}
.input{
    margin : 20px 0 30px;
}
`;
