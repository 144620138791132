import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const MessagingWrapper = styledComponents.div`
margin : 55px 31px 0 31px;
.heading{
    font-weight: 500;
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
    font-fammily : ${fonts.gilorySemiBold};
  
}
 .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #101e2d;
    margin-bottom: 5px;
    display: block;
  }

  select {
    border: 1px solid #d0d5d9;
    border-radius: 4px;
    padding: 10px;
    background: #FAFBFD;
    width: 12%;
    padding-right : 5px;
  }
  .menu-icon{
      cursor: pointer;
  }
.input-container{
 width: 33%;
 position: relative;
    img{
     position: absolute;
     left: 2.33%;
     top: 40%;
    }
}
.header-search{
background: #FAFBFD;
border-radius: 2px;
padding: 16px 15px 10px 30px;
height: 48px;
width : 100%;
 &::placeholder{
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #666666;
    }
 }
 .message_card_container{
     flex-wrap : wrap;
     margin-top : 31px;
     & > * {
        width : 48%;
     }
 }
`;
