import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const MonthWrapper = styledComponents.div`
 table {
    width: 100%;
  }
  .weekdays-title{
    font-weight: 500;
    font-size: 13px;
    line-height: 157%;
    font-family : ${fonts.giloryMedium};
  }
  td{
      border: 1px solid #EEF4FF;
      height : 135px;
      padding  : 5px;
      flex: 1;
      overflow-y: scroll;
  }
  .thead-row{
    display: flex;
    justify-content: space-between;
    th{
        flex: 1;
    }
  }
  tbody{
      margin-top : 15px;
      .day{
        font-size: 12px;
        line-height: 125%;
        color: #000000;
        font-family : ${fonts.giloryMedium};
      }
      .todays-date{
            text-align: center;
            margin-left: auto;
            height: 30px;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
           background: #17568b;
           color: #FFFFFF;
      }
  }
`;
