import styledComponents from "styled-components";

export const CalenderWrapper = styledComponents.div`
padding : 30px 20px;
.content{
    margin-top : 41px;
}
   @media (max-width: 576px) {
     padding : 20px 10px;
   }
`;
