import React, { useEffect, useState } from "react";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";

import { FlexBox } from "components/reusables/FlexBox";

import MonthView from "./MonthView";
import WeekView from "./WeekView";
import { getISOWeek, selectWeek } from "utils/helpers";
import { ReactComponent as Line } from "assets/images/icons/Line117.svg";
import { ScheduleWrapper } from "./styles/styled.schedule";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
const Schedule = () => {
  const date = new Date();
  const isoYear = dayjs().isoWeekYear();
  const isoWeek = dayjs().isoWeek();
  const [currentWeek, setCurrentWeek] = useState(selectWeek(date));
  const [weekInYearCount, setWeekInYearCount] = useState(isoWeek);

  const firstDate = dayjs(currentWeek[1]).format("ddd, MMM D");
  const lastDate = dayjs(currentWeek[5]).format("ddd, MMM D");

  const handlePrevWeek = () => {
    if (weekInYearCount > isoWeek) {
      setWeekInYearCount((prev) => prev - 1);
    }
  };

  const handleNextWeek = () => {
    setWeekInYearCount((prev) => prev + 1);
  };

  useEffect(() => {
    const weekOut = getISOWeek(`${weekInYearCount} ${isoYear}`);
    setCurrentWeek(selectWeek(weekOut[0]));
  }, [weekInYearCount, isoYear]);

  return (
    <ScheduleWrapper>
      <FlexBox>
        <h1 className="heading">Schedule</h1>
      </FlexBox>
      <FlexBox className="weekly-heading">
        <p className="title">Weekly Schedule</p>
        <FlexBox className="month_header">
          <div onClick={handlePrevWeek}>
            <BsChevronLeft />
          </div>
          <p className="week mb-0">
            <span className="mx-4"> {firstDate}</span> <Line />{" "}
            <span className="mx-4">{lastDate}</span>
          </p>
          <div onClick={handleNextWeek}>
            <BsChevronRight />
          </div>
        </FlexBox>
      </FlexBox>
      <FlexBox className="schedule-table gap-2" align="flex-start">
        <div className="month-view">
          <MonthView currentWeek={currentWeek} />
        </div>

        <div className="week-view">
          <WeekView currentWeek={currentWeek} />
        </div>
      </FlexBox>
    </ScheduleWrapper>
  );
};

export default Schedule;
