import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assets/images/icons/Vector(11).svg";
import { FlexBox } from "./FlexBox";
const Modal = ({ children, close, height, width }) => {
  return (
    <Modal.Wrapper>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
          close();
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="modal-body"
          style={{
            height: height ? height : "auto",
            width: width ? width : "770px",
            left: `calc(50% - ${width ? width : "770px"} / 2 + 2px)`,
          }}
        >
          <FlexBox direction="row" justify="flex-end">
            <div className="close-icon">
              <CloseIcon onClick={close} />
            </div>
          </FlexBox>

          {children}
        </div>
      </div>
    </Modal.Wrapper>
  );
};

export default Modal;

Modal.Wrapper = styled.div`
  .modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  h3 {
    margin: 0;
  }

  .modal-body {
    position: absolute;
    width: 600px;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    overflow-y: scroll;
  }

  .close-icon {
    text-align: end;
  }
  .close-icon svg {
    font-size: 19px;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    .modal-body {
      width: 100% !important;
      left: 0 !important;
    }
  }
`;
