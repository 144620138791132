import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useQuery } from "react-query";

import { getRandomColor } from "utils/helpers";
import DayTimeline from "./DayTimeline";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import { getWeekSchedule } from "api/calendar";
import MiniMonth from "./MiniMonth";

import { DayWrapper } from "../styles/styled.day";
import { dayTimelineData } from "../data";

const Day = ({ daySelected, setDaySelected }) => {
  const [height, setHeight] = useState(0);

  let endTime = "18:00:00";
  let currT = moment().format("HH:mm:ss");
  const elementRef = useRef(null);

  const { isLoading, data, status } = useQuery(
    [
      "get-week-schedule",
      {
        firstDate: daySelected,
        lastDate: daySelected,
      },
    ],
    getWeekSchedule
  );

  useEffect(() => {
    if (status === "success") {
      var mins = moment
        .utc(moment(endTime, "HH:mm:ss").diff(moment(currT, "HH:mm:ss")))
        .format("HH:mm:ss");
      const timeInMins = 480 - moment.duration(mins).asMinutes();
      const height = (timeInMins * 93) / 60;
      const percent =
        (height / (elementRef?.current?.clientHeight || 744)) * 100;
      setHeight(percent);
    }
  }, [status, currT, endTime]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const getTimeData = (time) => {
    let item = data?.data?.find((item) => item?.meetingTime === time);
    if (item !== undefined) {
      item.hasEvent = true;
    } else {
      item = {
        hasEvent: false,
      };
    }

    return item;
  };

  return (
    <DayWrapper align="flex-start">
      <div className="timeline" ref={elementRef}>
        <div
          className="red_line"
          style={{
            top: `${height}%`,
            display: height < 0 || height > 100 ? "none" : "block",
          }}
        ></div>

        {dayTimelineData.map((time) => (
          <DayTimeline
            time={`${time} ${time < 12 ? "AM" : "PM"}`}
            colorObj={getRandomColor()}
            item={getTimeData(`${time}:00`)}
            hasEvent={getTimeData(`${time}:00`).hasEvent}
            key={time}
          />
        ))}
      </div>
      <div className="month">
        <MiniMonth setDaySelected={setDaySelected} daySelected={daySelected} />
      </div>
    </DayWrapper>
  );
};

export default Day;
