import styled from "styled-components";
import { fonts } from "components/constants";
export const MonthViewWrapper = styled.div`
  .month_header {
    margin-bottom: 24px;

    svg {
      cursor: pointer;
    }
    p {
      font-size: 14px;
      line-height: 125%;
      color: #101e2d;
      font-family: ${fonts.giloryMedium};
    }
  }
  .weekdays-title {
    font-size: 12px;
    line-height: 125%;
    color: #101e2d;
  }
  .month {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: #666666;
  }
  .week-days {
    margin-bottom: 27px;
    span {
      font-weight: 300;
      font-size: 12px;
      line-height: 125%;
      letter-spacing: 0.03em;
      color: #000000;
      width: 14%;
      text-align: center;
    }
  }
  table {
    width: 100%;
  }

  .days {
    span {
      text-align: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 125%;
      letter-spacing: 0.03em;
      margin: 6px 0;
      padding: 1rem 0;
      border-radius: 10px;
      position: relative;
      display: block;
      text-align: center;
      height: 30px;
      width: 30px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .today span,
  .selected-day span {
    background: #17568b;
    color: #ffffff !important;
    font-size: 12px;
    line-height: 125%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .weekend {
    span {
      cursor: default;
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .active_week {
    background: #eef4ff;
  }
`;
