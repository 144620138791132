import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Login from "components/Authentication/Login/Login";
import Dashboard from "components/Dashboard/Dashboard";
// import ForgotPassword from "components/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "components/Authentication/ResetPassword/ResetPassword";
import PrivateRoutes from "components/RoutesWrapper/PrivateRoutes";
import PublicRoutes from "components/RoutesWrapper/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
	const client = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	return (
		<BrowserRouter>
			<QueryClientProvider client={client}>
				<Switch>
					<PublicRoutes path="/login" component={Login} />
					{/* <PublicRoutes path="/forgot-password" component={ForgotPassword} /> */}
					<PublicRoutes
						path="/reset-password/:token"
						component={ResetPassword}
					/>
					<PrivateRoutes path="/" component={Dashboard} />
				</Switch>
			</QueryClientProvider>
			<ToastContainer />
		</BrowserRouter>
	);
};

export default App;
