import { Link } from "react-router-dom";

const ResetPasswordSuccess = () => {
  return (
    <div className="forgot-success p-5">
      <div className="greeting">
        <p>Password Reset Successful</p>
      </div>
      <p className="my-4">You can proceed to login with your new password</p>
      <Link className="mt-5 text-center" to="/login">
        <span className="forgot-password pointer">Login</span>
      </Link>
    </div>
  );
};

export default ResetPasswordSuccess;
