import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const WeekViewWrapper = styledComponents.div`
 table {
    width: 100%;
  }
  thead{
      .time {
          flex : 0.5
      }
      .weekdays-header{
            flex : 1;
            .month{
            font-size: 11px;
            line-height: 137%;
            color: rgba(102, 102, 102, 0.79);
            font-family : ${fonts.gilorySemiBold};
            }
            .day{
            font-size: 13px;
            line-height: 17.81px;
            font-family : ${fonts.gilorySemiBold};
            }
      }
  }
  tbody{
         .time {
          flex : 0.5
      }
.weekdays-body{
    flex : 1;
    padding : 6px;
}
  }
    .thead-row{
    display: flex;
    justify-content: space-between;
    th, td{
    border : 1px solid #EEF4FF;
    height : 54px;
    display: flex;
    justify-content: center;
    align-items: center;
     flex-direction: column;
    }
  }
`;
