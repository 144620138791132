import React, { useState } from "react";

import { ReactComponent as Icon } from "assets/images/icons/Vector(7).svg";
// import { ReactComponent as Forward } from "assets/images/icons/Vector(8).svg";
// import { ReactComponent as Eye } from "assets/images/icons/Vector(9).svg";
import { ReactComponent as Thrash } from "assets/images/icons/Vector(10).svg";
import Modal from "components/reusables/Modal";
import SingleMessage from "./SingleMessage";
import DeleteMessage from "./DeleteSingleMessage";

import { MessageCardWrapper } from "./styles/styled.messageCard";

const MessageCard = ({ message }) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleToggleDelete = () => {
    setDeleteModal(!deleteModal);
    setOpen(false);
  };

  return (
    <MessageCardWrapper onClick={handleToggle}>
      <div className="content">
        <div className="user-info d-flex gap-2">
          <Icon />
          <div className="user">
            <p className="username">
              {message?.firstName} {message?.lastName}
            </p>
            <p className="email">{message?.email}</p>
          </div>
        </div>
        <div className="text">{message?.message}</div>
      </div>
      <div
        className="icons"
        onClick={(e) => {
          e.stopPropagation();
          handleToggleDelete();
        }}
      >
        <Thrash />
      </div>

      {open && (
        <Modal close={handleToggle}>
          <SingleMessage message={message} close={handleToggle} />
        </Modal>
      )}

      {deleteModal && (
        <Modal close={handleToggleDelete} width="500px">
          <DeleteMessage id={message?._id} close={handleToggleDelete} />
        </Modal>
      )}
    </MessageCardWrapper>
  );
};

export default MessageCard;
