import styledComponents from "styled-components";
import { FlexBox } from "components/reusables/FlexBox";

export const AnalyticsWrapper = styledComponents(FlexBox)`
gap : 10px;

.small-card{
    flex : 1;
 .info {
      margin-bottom : 60px;
    }
    .amount{
      font-weight: 400;
      font-size: 28px;
      line-height: 157%;
      color: #101E2D;
      width : 20%;
    }
    .title{
      div {
        font-weight: 500;
        font-size: 11px;
        line-height: 157%;
        color: #63B67F;
      }
      p{
        font-weight: 500;
        font-size: 12px;
        line-height: 157%;
        color: #666666;
      }
    }
}
.large-card{
    flex : 2;
     height: 229px;
}
.keys{
.dot{
  width: 15px;
  height: 15px;
  background: #28C479;
  border-radius : 50px;
}
.dot.dot-2{
  background: #FF9292;
}
.label{
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
  color: #666666;
}
.value{
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  color: #666666;
  margin-left: 20px;
}
}

    @media (max-width: 576px) {
      flex-direction : column;
      .small-card{
            width : 100%;
      }
    }
`;
