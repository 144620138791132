import { fonts } from "components/constants";
import styledComponents from "styled-components";

export const DashboardStyles = styledComponents.div`
margin-top : 55px;
margin-left : 31px;
.heading{
    font-weight: 500;
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
    font-fammily : ${fonts.gilorySemiBold};
    margin-bottom : 25px;
}
.text{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #666666;
    margin : 0;
}
.dash-container{
    gap : 10px;
}
.right-side{
    flex : 3;
}
.left-side{
    flex : 1;
}

    @media (max-width: 576px) {
     margin-left  : 10px;
    .dash-container{
        flex-direction : column;
      }
      .right-side{
          width : 100%;
      }
    }
`;
