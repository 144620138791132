import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const AdminWrapper = styledComponents.div`
margin-top : 48px;
padding : 0 27px;
.heading{
    font-family : ${fonts.giloryMedium};
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
}
.input-container{
 width: 33%;
 position: relative;
    img{
     position: absolute;
     left: 2.33%;
     top: 40%;
    }
}
.header-search{
background: #FAFBFD;
border-radius: 2px;
padding: 16px 15px 10px 30px;
height: 48px;
width : 100%;
 &::placeholder{
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  color: #666666;
    }
 }
 .admins-container{
    flex-wrap : wrap;
    gap: 31px;
    margin-top : 42px;
    & > * {
        width : 17.9%;
    }
 }
`;
