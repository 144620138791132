import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const SingleMessageWrapper = styledComponents.div`
cursor : default;
padding : 20px;
    .username{
        font-weight: 600;
        font-size: 14px;
        line-height: 147%;
        font-family : ${fonts.giloryBold};
        color: #101E2D;
        text-transform: capitalize;
    }
    .email{
        font-weight: 500;
        font-size: 12px;
        line-height: 69%;
        color: rgba(16, 30, 45, 0.43);
        font-family : ${fonts.gilorySemiBold};
        margin-bottom : 11px;
    }
    .time{
        font-weight: 600;
        font-family : ${fonts.giloryBold};
        font-size: 12px;
        line-height: 69%;
        color: rgba(16, 30, 45, 0.43);
    }
    .message-content{
        font-weight: 400;
        font-size: 14px;
        line-height: 179%;
        color: #101E2D;
        margin : 24px 0 42px;
    }

`;

export const CtaButtons = styledComponents.button`
    height: 36px;
    padding: 12px;
    font-weight: 600;
    font-family : ${fonts.gilorySemiBold};
    font-size: 13px;
    line-height: 125%;
    color: ${(props) => props.color};
    background: ${(props) => props.background};
    border: ${(props) => (props.hasBorder ? "1px solid #F4F8FF" : "none")};
    border-radius: 2px;
    svg{
        margin-right : 5px;
    }
`;
