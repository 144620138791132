import React from "react";

import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";

import CustomInputBox from "../Careers/Input";
import Button from "components/reusables/Button";
import { VALIDATION_SCHEMA_ADD_ADMIN } from "./validation";
import { addAdmin } from "api/admin";
import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { access_levels, ADD_ADMIN_INITIAL_VALUES } from "./data";

import { AddAdminWrapper } from "./styles/addAdmin.styled";
import CustomPassword from "utils/CustomInputBox/CustomPassword";

const AddAdmin = ({ close }) => {
  const queryClient = useQueryClient();

  const addAdminMutation = useMutation(addAdmin, {
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
    onSuccess: () => {
      queryClient.refetchQueries("get-all-users");
      close();
      toastSuccess("User created successfully");
    },
  });

  const formik = useFormik({
    initialValues: ADD_ADMIN_INITIAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA_ADD_ADMIN,
  });

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  function handleSubmit(values) {
    addAdminMutation.mutateAsync(values);
  }

  return (
    <AddAdminWrapper>
      <h1>Add New Admin</h1>
      <CustomInputBox
        name="firstName"
        label="First Name"
        placeholder={"Type here"}
        value={formik.values.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("firstName")}
        errorMessage={formik.errors.firstName}
      />
      <CustomInputBox
        name="lastName"
        label="Last Name"
        placeholder={"Type here"}
        value={formik.values.lastName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("lastName")}
        errorMessage={formik.errors.lastName}
      />
      <CustomInputBox
        name="email"
        label="Email Address"
        placeholder={"Type here"}
        value={formik.values.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("email")}
        errorMessage={formik.errors.email}
      />
      <CustomPassword
        name="password"
        label="Password"
        placeholder={"Type here"}
        value={formik.values.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={hasErrors("password")}
        errorMessage={formik.errors.password}
      />

      <div>
        <p className="admin-header">Assign admin access</p>
        {access_levels.map((item) => (
          <div class="radio-input" key={item.value}>
            <input
              type="radio"
              name={"userRole"}
              id={item.label}
              value={item.value}
              onChange={formik.handleChange}
              checked={formik.values.userRole === item.value}
              className={
                formik.values.userRole === item.value
                  ? "active-border radio"
                  : "radio"
              }
            />
            <label class="radio-label" htmlFor={item.label}>
              {item.label}
            </label>
          </div>
        ))}
        {hasErrors("userRole") && (
          <small className="input-text-error">{formik.errors.userRole}</small>
        )}
      </div>
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        disabled={addAdminMutation.isLoading}
      >
        {addAdminMutation.isLoading ? "Loading..." : "Add Admin"}
      </Button>
    </AddAdminWrapper>
  );
};

export default AddAdmin;
