import styledComponents from "styled-components";
import { FlexBox } from "components/reusables/FlexBox";

export const UpcomingScheduleCardWrapper = styledComponents(FlexBox)`
background: ${(props) => props.bgColor};
padding : 8px 25px 8px 11px; 
margin : 10px 0;
border-radius: 2px;
.time{
color: ${(props) => props.color};
font-weight: 600;
font-size: 14px;
line-height: 157%;
}
.user{
  width : 70%;
}
.name{
    font-weight: 500;
    font-size: 12px;
    line-height: 157%;
    color: #101E2D;
}
.email{
    font-weight: 500;
    font-size: 12px;
    line-height: 157%;
    color: rgba(16, 30, 45, 0.42);
}
`;
