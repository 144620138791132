import styled from "styled-components";
import { fonts } from "components/constants";

export const AuthLayoutStyles = styled.div`
  background: #edf6ff;
  height: 100vh;
  .login-header {
    padding: 42px;
  }
  .links a {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    text-align: center;
    color: rgba(0, 70, 133, 0.47);
  }
  .links :first-child {
    margin-right: 38px;
  }
  .form-container {
    width: 30%;
    margin: auto;
    height: 77vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .greeting {
    & :first-child {
      font-family: ${fonts.gilorySemiBold};
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      color: #000000;
      span {
        font-family: ${fonts.giloryBold};
        color: #509fef;
      }
    }
  }
  .forgot-password {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #509fef;
  }
  form {
    margin-top: 28px;
  }
  .input {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(88, 153, 219, 0.1);
    border-radius: 2px;
    height: 60px;
    padding: 10px 15px;
    position: relative;
    margin: 16px 0;
    input {
      position: absolute;
      bottom: 0;
      height: 50%;
      left: 0;
      width: 100%;
      padding: 10px 15px;
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(10, 58, 100, 0.24);
      }
    }
    .label {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #666666;
      font-family: ${fonts.gilorySemiBold};
    }
  }

  button {
    background: #509fef;
    border-radius: 2px;
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    margin-top: 18px;
  }
  .footer-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 157%;
    text-align: center;
    color: rgba(23, 86, 139, 0.38);
  }

  .forgot-success {
    background: white;
  }
  @media (max-width: 576px) {
    .login-header {
      padding: 10px 0;
    }
    .form-container {
      width: 90%;
    }
  }
`;
