import React from "react";

import user from "assets/images/icons/Ellipse102.svg";

import { UserWrapper } from "./styles/styled.user";
const User = ({ data }) => {
  return (
    <UserWrapper className="d-flex ">
      <img src={user} alt="" />
      <div className="name">
        {data?.firstName} {data?.lastName}
      </div>
      <div className="email">{data?.email}</div>
    </UserWrapper>
  );
};

export default User;
