import { useState } from "react";
import { ReactComponent as EyeOffIcon } from "assets/images/icons/eye-off.svg";
import { ReactComponent as EyeOnIcon } from "assets/images/icons/eye-on.svg";
import "./CustomInputBox.scss";

const CustomPassword = ({
  id,
  name,
  value,
  error,
  label,
  onChange,
  placeholder = "Password",
  required,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const isError = error ? "error" : "";
  const isRequired = required ? "required" : "";
  const show_password = showPassword ? "show__password" : "";

  return (
    <>
      <div className="form-group input">
        {label && (
          <label
            htmlFor={name}
            className={`form-group-label label ${isRequired}`}
          >
            {label}
          </label>
        )}

        <div
          className={`input-wrapper password-wrapper d-flex align-items-center justify-content-end ${isError} `}
        >
          <input
            type={showPassword ? "text" : "password"}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            autoComplete="off"
            onChange={onChange}
            {...rest}
          />

          <div className={`password ${show_password}`}>
            {showPassword ? (
              <EyeOnIcon
                width="30px"
                onClick={() => setShowPassword((prev) => !prev)}
              />
            ) : (
              <EyeOffIcon
                width="30px"
                onClick={() => setShowPassword((prev) => !prev)}
              />
            )}
          </div>
        </div>
      </div>
      {typeof error === "string" && (
        <small className="input-text-error">{error}</small>
      )}
    </>
  );
};

export default CustomPassword;
