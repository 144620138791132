import styledComponents from "styled-components";
import { CardOverlay } from "components/reusables/FlexBox";

export const SingleAdminCardWrapper = styledComponents(CardOverlay)`
padding : 15px;
cursor : pointer;
.icon{
    text-align: end;
}
.user{
    flex-direction : column;
align-items : center;
justify-content : center;
margin-top : 15px;
}
.name{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #000000;
    margin : 5px 0;
}
    .email{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #979797;
    }
    .role{
        background: #F4F8FF;
        border: 1px solid rgba(80, 159, 239, 0.11);
        border-radius: 2px;
        padding : 5px 8px;
        font-size: 13px;
        line-height: 125%;
        color: #56A3ED;
        margin-top : 15px;
        text-transform: capitalize;
    }
`;
