export const storeUserData = (userData) => {
  try {
    sessionStorage.setItem("userData", userData);
  } catch (error) {
    throw new Error("Session storage permission is needed");
  }
};

export const fetchUserData = () => {
  try {
    let userData = sessionStorage.getItem("userData");
    return userData;
  } catch (error) {
    throw new Error("Session storage permission is needed");
  }
};

export const deleteUserData = () => {
  try {
    sessionStorage.removeItem("userData");
  } catch (error) {
    throw new Error("Session storage permission is needed");
  }
};
