import api from "api";

export function getDashboardData() {
  return api
    .get(`/admin/dashboard/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
