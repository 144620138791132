import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const MessageCardWrapper = styledComponents.div`
display: flex;
justify-content:normal;
border-radius: 2px;
background: #FFFFFF;
height: 133px;
box-shadow: 0px 4px 20px rgba(88, 153, 219, 0.1);
margin : 10px 0;
cursor : pointer;
p{
    margin-bottom : 0;
}
.content {
    width: 94%;
    padding : 14px 0 14px 18px;
    .text{
        font-weight: 400;
        font-size: 12px;
        line-height: 157%;
        color: #101E2D;
        font-family : ${fonts.giloryRegular};
        height : 61px;
        overflow: hidden;
        text-overflow: ellipsis;
}
    }
    .user{
        margin-top: -3px;
    }
    .username{
        font-weight: 600;
        font-size: 14px;
        line-height: 147%;
        font-family : ${fonts.giloryBold};
        color: #101E2D;
        margin-bottom : 7px;
    }
    .email{
        font-weight: 500;
        font-size: 12px;
        line-height: 69%;
        color: rgba(16, 30, 45, 0.43);
        font-family : ${fonts.gilorySemiBold};
        margin-bottom : 11px;
    }
}
.icons {
    width: 6%;
    background: #FAFBFD;
    display: flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items : center;
    padding-top: 14px;
}
`;
