import React from "react";

import UpcomingScheduleCard from "../DashContent/UpcomingScheduleCard";
import { getRandomColor } from "utils/helpers";
import { FlexBox } from "components/reusables/FlexBox";
import { obj } from "./data";

import user from "assets/images/icons/Ellipse102.svg";

import { ViewAdminModalWrapper } from "./styles/styled.viewAdminModal";

const ViewAdminModal = ({ data }) => {
  return (
    <ViewAdminModalWrapper>
      <FlexBox align="flex-start" className="top-wrapper">
        <div className="users d-flex">
          <img src={user} alt="" />
          <div className="name">
            {" "}
            {data?.firstName} {data?.lastName}
          </div>
          <div className="email">{data?.email}</div>
          <div className="role">{data?.userRole}</div>
        </div>
        <div className="right">
          <FlexBox>
            <div className="key_pair">
              <div className="key">Date Added</div>
              <div className="value">13 Sept, 2022</div>
            </div>
            {/* <div className="key_pair w-50">
              <div className="key">Added by</div>
              <div className="value">Salome Eromi</div>
            </div> */}
          </FlexBox>
          {/* <p className="admin-header">Access</p>
          <label class="checkbox">
            <input type="checkbox" /> <span>Create schedule</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" /> <span>Reply customers messages</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" /> <span>Be assigned meeting schedules</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" /> <span>Add new Members</span>
          </label>{" "}
          <label class="checkbox">
            <input type="checkbox" /> <span>Manage Careers</span>
          </label> */}
        </div>
      </FlexBox>
      <div className="schedules">
        <p className="schedule-header">Upcoming Schedule</p>
        {Array(5)
          .fill(5)
          .map(() => (
            <UpcomingScheduleCard
              item={obj}
              hasNoIcon
              colorObj={getRandomColor()}
            />
          ))}
      </div>
    </ViewAdminModalWrapper>
  );
};

export default ViewAdminModal;
