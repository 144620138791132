import api from "api";

export function getAdmin() {
  return api
    .get(`/authentication/users`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function addAdmin(payload) {
  return api
    .post(`/authentication/signUp`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteAdmin(id) {
  return api
    .delete(`/authentication/user/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
