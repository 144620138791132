import React from "react";

import { CardOverlay } from "components/reusables/FlexBox";

import mail from "assets/images/icons/Icon.svg";
import career from "assets/images/icons/Icon(1).svg";
import { AnalyticsWrapper } from "./styles/styled.analytics";

export const Analytics = ({ data }) => {
  return (
    <AnalyticsWrapper>
      <CardOverlay className="small-card">
        <div className="d-flex align-center gap-2 info">
          <img src={mail} alt="" />
          <p className="text">Messages</p>
        </div>
        <div className="d-flex align-center gap-5">
          <p className="amount">{data?.totalMessage}</p>
          <div className="title">
            <div className="new">{data?.totalMessage} </div>
            <p> messages</p>
          </div>
        </div>
        <div className="d-flex align-center gap-5 ">
          <p className="amount">62</p>
          <div className="title">
            <div className="new">34 new</div>
            <p>Achived messages</p>
          </div>
        </div>
      </CardOverlay>
      <CardOverlay className="small-card">
        <div className="d-flex align-center gap-2 info">
          <img src={mail} alt="" />
          <p className="text">Schedule</p>
        </div>
        <div className="d-flex align-center gap-5">
          <p className="amount">{data?.upcomingSchedulesCount}</p>
          <div className="title">
            <div className="new">{data?.upcomingSchedulesCount} Upcoming</div>
            <p> Schedules</p>
          </div>
        </div>
        <div className="d-flex align-center gap-5 ">
          <p className="amount">{data?.schedules}</p>
          <div className="title">
            <div className="new">{data?.schedules} total</div>
            <p> scheduled</p>
          </div>
        </div>
      </CardOverlay>
      <CardOverlay className="small-card">
        <div className="d-flex align-center gap-2 info">
          <img src={career} alt="" />
          <p className="text">Careers</p>
        </div>
        <div className="d-flex align-center gap-5">
          <p className="amount">{data?.upcomingSchedulesCount}</p>
          <div className="title">
            <div className="new">{data?.upcomingSchedulesCount} Upcoming</div>
            <p> Schedules</p>
          </div>
        </div>
        <div className="d-flex align-center gap-5 ">
          <p className="amount">{data?.schedules}</p>
          <div className="title">
            <div className="new">{data?.schedules} total</div>
            <p> scheduled</p>
          </div>
        </div>
      </CardOverlay>
    </AnalyticsWrapper>
  );
};
