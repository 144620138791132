import styled from "styled-components";
import { fonts } from "components/constants";

export const BoxWrapper = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 125%;
  font-family: ${fonts.giloryMedium};
  cursor: ${(props) => props.cursor};
`;
