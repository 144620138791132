import React, { useState } from "react";

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";

import ViewAdminModal from "./ViewAdminModal";
import Modal from "components/reusables/Modal";
import DeleteAdmin from "./DeleteAdmin";

import user from "assets/images/icons/Ellipse102.svg";
import { ReactComponent as MenuIcon } from "assets/images/icons/Vector(17).svg";

import { SingleAdminCardWrapper } from "./styles/styled.singleAdminCard";

const SingleAdminCard = ({ data }) => {
  const [openAdmin, setOpenAdmin] = useState(false);
  const [key, setKey] = useState("");
  const toggleOpenAdmin = () => setOpenAdmin(!openAdmin);

  const menu = (
    <Menu onSelect={onSelect}>
      <MenuItem key="1">Delete user</MenuItem>
    </Menu>
  );

  function onSelect({ key }) {
    setKey(key);
  }

  function handleClose() {
    setKey("");
    setOpenAdmin(false);
  }

  return (
    <>
      <SingleAdminCardWrapper className="" onClick={toggleOpenAdmin}>
        <div className="icon " onClick={(e) => e.stopPropagation()}>
          <Dropdown trigger={["click"]} overlay={menu} animation="slide-up">
            <MenuIcon className="menu-icon" />
          </Dropdown>
        </div>
        <div className="user d-flex">
          <img src={user} alt="" />
          <div className="name">
            {" "}
            {data?.firstName} {data?.lastName}
          </div>
          <div className="email">{data?.email}</div>
          <div className="role">{data?.userRole}</div>
        </div>

        {openAdmin && (
          <Modal close={toggleOpenAdmin} height="700px">
            <ViewAdminModal data={data} />
          </Modal>
        )}
      </SingleAdminCardWrapper>
      {key === "1" && (
        <Modal width="500px" close={handleClose}>
          <DeleteAdmin close={handleClose} id={data?._id} />
        </Modal>
      )}
    </>
  );
};

export default SingleAdminCard;
