import { useState } from "react";
import AuthLayout from "../AuthLayout";
import { STEPS } from "components/constants";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccess from "./ResetPasswordSuccess";

const { STEP_1 } = STEPS;

const ResetPassword = () => {
  const [step, setStep] = useState(STEP_1);
  return (
    <AuthLayout>
      {step === STEP_1 ? (
        <ResetPasswordForm setStep={setStep} />
      ) : (
        <ResetPasswordSuccess />
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
