import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import { getMonth } from "utils/helpers/index";
import { FlexBox } from "components/reusables/FlexBox";
import { format } from "../data";

import { MiniMonthWrapper } from "../styles/styled.minimonth";

export default function MiniMonth({ daySelected, setDaySelected }) {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }

  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  function getDayClass(day) {
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected;
    if (!slcDay && nowDay === currDay) {
      return "selected-day";
    } else if (currDay === slcDay) {
      return "selected-day";
    } else {
      return "";
    }
  }

  const handleClick = (day) => {
    const slcDay = day?.format(format);
    const weekend = isWeekend(day);
    if (weekend !== "") {
      return;
    }
    setSmallCalendarMonth(currentMonthIdx);
    setDaySelected(slcDay);
  };

  function isWeekend(day) {
    if (
      day.format("ddd").toUpperCase() === "SUN" ||
      day.format("ddd").toUpperCase() === "SAT"
    ) {
      return "weekend";
    }
    return "";
  }

  return (
    <MiniMonthWrapper>
      <FlexBox className="month_header">
        <div onClick={handlePrevMonth}>
          <BsChevronLeft />
        </div>
        <p className="mb-0">
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM")}
        </p>
        <div onClick={handleNextMonth}>
          <BsChevronRight />
        </div>
      </FlexBox>
      <table className="">
        <thead className="">
          <tr>
            {currentMonth[0].map((day, i) => (
              <th key={i} scope="col">
                <span className="d-block text-center weekdays-title">
                  {day.format("ddd").toUpperCase()}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="days">
          {currentMonth.map((row, i) => (
            <tr key={i}>
              {row.map((day, idx) => (
                <td
                  key={idx}
                  onClick={() => {
                    handleClick(day);
                  }}
                  className={` ${getDayClass(day)} ${isWeekend(day)}`}
                >
                  <span className="">{day.format("D")}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </MiniMonthWrapper>
  );
}
