import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const ViewAdminModalWrapper = styledComponents.div`
border: 1px solid #EEF4FF;
margin-top : 22px;
.top-wrapper{
    padding-bottom : 10px;
}
.users{
    flex-direction : column;
align-items : center;
justify-content : center;
padding : 30px 0;
flex : 1;
border-right: 1px solid #EEF4FF;
}
.right {
    flex : 2;
    padding : 41px 0 0 32px;
}
.key{
    font-weight: 600;
font-size: 12px;
line-height: 69%;
color: rgba(16, 30, 45, 0.43);
font-family : ${fonts.gilorySemiBold};
margin-bottom: 12px;

}
.value{
    font-weight: 600;
font-size: 13px;
line-height: 125%;
font-family : ${fonts.gilorySemiBold};
color: #666666;
}
.name{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #000000;
    margin : 5px 0;
}
    .email{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #979797;
    }
    .role{
        background: #F4F8FF;
        border: 1px solid rgba(80, 159, 239, 0.11);
        border-radius: 2px;
        padding : 5px 8px;
        font-size: 13px;
        line-height: 125%;
        color: #56A3ED;
        margin-top : 15px;
        text-transform: capitalize;
    }
    .admin-header{
font-weight: 600;
font-size: 12px;
line-height: 69%;
margin : 32px 0 18px;
color: rgba(16, 30, 45, 0.43);
}
input[type="checkbox"]{
    height: 15px;
    width: 15px;
    vertical-align: middle;
}
.checkbox{
    margin: 13px 0;
    display: block;
    span{
    font-weight: 400;
    font-size: 13px;
    line-height: 125%;
    color: #666666;
     margin-left: 9px;
    
    }
}
.schedules{
    padding : 22px 28px;
    border-top: 1px solid #EEF4FF;
}
.schedule-header{
    font-weight: 600;
font-size: 14px;
line-height: 157%;
color: #666666;
}
`;
