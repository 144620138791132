import { CustomInputBoxWrapper } from "./styles/styled.input";

export default function CustomInputBox({
  type = "text",
  name,
  label,
  placeholder = "Type Here",
  value,
  error,
  handleChange,
  onFocus,
  required,
  disabled,
  isTextarea,
  errorMessage,
  ...rest
}) {
  return (
    <CustomInputBoxWrapper error={error}>
      <div className="input-wrapper">
        {label && (
          <label htmlFor={name} className={``}>
            {label}
          </label>
        )}
        {isTextarea ? (
          <textarea
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            autoComplete="off"
            disabled={disabled}
            onChange={handleChange}
            required={required}
            {...rest}
          ></textarea>
        ) : (
          <input
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            autoComplete="off"
            disabled={disabled}
            onChange={handleChange}
            required={required}
            {...rest}
          />
        )}
      </div>

      {error && <small className="input-text-error">{errorMessage}</small>}
    </CustomInputBoxWrapper>
  );
}
