import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import getRoutes from "./routes";

const SubRoutes = () => {
  const role = localStorage.getItem("role");
  return (
    <Switch>
      {getRoutes(role).map(({ path, exact, component, dropdown }, i) =>
        dropdown && dropdown?.length > 0 ? (
          dropdown?.map(({ path, exact, component }, i) => (
            <Route key={i} path={path} exact={exact} component={component} />
          ))
        ) : (
          <Route key={i} path={path} exact={exact} component={component} />
        )
      )}

      <Route exact path="*" component={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default SubRoutes;
