import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const AddNewOpeningWrapper = styledComponents.div`
h1{
font-weight: 400;
font-size: 26px;
line-height: 31px;
}
& > * {
    margin : 30px 0;
}
.radio-label{
    font-size: 13px;
    line-height: 125%;
    color: #666666;
        cursor: pointer;
}
 .input-text-error {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    display: block;
  }
input[type="radio"] {
  margin-right: 1.3rem;
  visibility: hidden; 
}
.radio-input{
    margin : 13px 0;
}
.heading-2{
    font-size: 12px;
line-height: 69%;
  font-family : ${fonts.gilorySemiBold};
  color: #666666;
  margin-bottom : 18px;
}
input[type=radio]:before {
        position: relative;
        margin: -2px -25px -4px 0;
        display: inline-block;
        visibility: visible;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #C4C4C4;;
        content: "";
    }
    input[type=radio]:checked:after {
            position: relative;
            top: 0;
            left: 9px;
            display: inline-block;
            visibility: visible;
            border-radius: 6px;
            width: 12px;
            height: 12px;
            content: "";
        }
        input[type=radio].radio:checked:after {
             background-color : #56A3ED;
           
            }
            input[type=radio]:checked{
            background: #0DFF92;
            }
    .active{
        color : #56A3ED;
    }
    .inactive{
        color : #5E5475;
    }

`;
