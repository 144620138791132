import styledComponents from "styled-components";

import { FlexBox } from "components/reusables/FlexBox";
import { fonts } from "components/constants";

export const DayTimelineWrapper = styledComponents(FlexBox)`
gap : 12px;

height : 93px;
.card-c{
    background: ${(props) => props.bgColor};
    padding : 8px 25px 8px 11px; 
    margin : 3.5px 0;
    border-radius: 2px;
}
.time{
    font-weight: 300;
    font-size: 11px;
    line-height: 157%;
    color: #666666;
    font-family : ${fonts.giloryRegular}
}
.left{
   width : 95%;
   border-top: 1px solid #EEF4FF;
     margin-top : 5px;
}
.name{
    font-weight: 500;
    font-size: 12px;
    line-height: 157%;
    color: #101E2D;
     font-family : ${fonts.giloryMedium}
}
.email{
    font-weight: 500;
    font-size: 12px;
    line-height: 157%;
    color: rgba(16, 30, 45, 0.42);
    font-family : ${fonts.giloryMedium}
}
`;
