import api from "api";
export function getOpenRoles(key) {
  const { active } = key.queryKey[1];
  return api
    .get(`/career/getRoles/?active=${active}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createOpenRoles(payload) {
  return api
    .post(`/career/createRole`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getDepartments() {
  return api
    .get(`/career/getDepartment`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function createDepartment(payload) {
  return api
    .post(`/career/createDepartment`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleApplicant(key) {
  const { id } = key.queryKey[1];
  return api
    .get(`/career/getRoleApplicant/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteDepartment(id) {
  return api
    .delete(`/career/deleteDepartment/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function changeRoleStatus(payload) {
  const { id, active } = payload;
  return api
    .put(`/career/updateRole/${id}?active=${active}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
