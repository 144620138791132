import { fonts } from "components/constants";
import styled from "styled-components";

const Button = styled.button`
  height: ${(props) => (props.height ? props.height : "36px")};
  background: ${(props) => (props.background ? props.background : "#17568B")};
  border-radius: 2px;
  border: ${(props) => (props.border ? props.border : "none")};
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => (props.fs ? props.fs : "13px")};
  line-height: 16px;
  padding: ${(props) => (props.padding ? props.padding : "0 24px")};
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: ${(props) => (props.fw ? props.fw : "400")};
  font-family: ${(props) => (props.ff ? props.ff : fonts.gilorySemiBold)};
  white-space: nowrap;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export default Button;
