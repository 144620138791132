import React from "react";

import { useMutation, useQueryClient } from "react-query";

import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { FlexBox } from "components/reusables/FlexBox";
import Button from "components/reusables/Button";
import { deleteAdmin } from "api/admin";

import { DeleteAdminWrapper } from "./styles/styled.deleteadmin";

const DeleteAdmin = ({ close, id }) => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(deleteAdmin, {
    onSuccess: () => {
      queryClient.refetchQueries("get-all-users");
      close();
      toastSuccess("User deleted successfully");
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const handleDelete = () => {
    deleteMutation.mutateAsync(id);
  };

  return (
    <DeleteAdminWrapper>
      <p>Are you sure you want to delete this user ? </p>
      <FlexBox direction="row" justifyContent="center" mb="1rem">
        <Button className="no" variant="contained" onClick={close}>
          No
        </Button>
        <Button
          className="yes"
          variant="contained"
          onClick={handleDelete}
          disabled={deleteMutation.isLoading}
        >
          {deleteMutation.isLoading ? "Loading..." : "Delete"}
        </Button>
      </FlexBox>
    </DeleteAdminWrapper>
  );
};

export default DeleteAdmin;
