import styled from "styled-components";
import { fonts } from "components/constants";

export const CustomInputBoxWrapper = styled.div`
  .input-wrapper {
    box-shadow: 0px 4px 20px rgba(88, 153, 219, 0.1);
    border-radius: 2px;
    padding: 15px 10px 5px 10px;
    border: 1px solid ${(props) => (props.error ? "red" : "white")};
  }
  label {
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 5px;
    font-family: ${fonts.giloryMedium};
  }
  input,
  textarea {
    // border-bottom: 1px solid rgba(10, 58, 100, 0.1);
    display: block;
    width: 100%;
    padding-left: 0;
    padding-bottom: 10px;
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(10, 58, 100, 0.4);
    }
  }
  textarea {
    height: 60px;
  }
  .input-text-error {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    display: block;
  }
`;
