import styled from "styled-components";
import { fonts } from "components/constants";

export const DeleteAdminWrapper = styled.div`
  button {
    width: 47%;
  }
  .no {
    background: #ffffff;
    border: 1px solid #17568b;
    border-radius: 2px;
    color: #496781;
  }
  .yes {
    // background: #8c3621;
  }
  p {
    font-size: 14px;
    line-height: 125%;
    font-family: ${fonts.gilorySemiBold};
    text-align: center;
    margin-bottom: 20px;
  }
`;
