import React from "react";

import dayjs from "dayjs";

import Events from "./Events";
import { getDaySchedules } from "../data";

export default function FullMonthView({ day, schedules }) {
  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "todays-date"
      : "";
  }

  return (
    <td className="" onClick={() => getDaySchedules(schedules, day)}>
      <div className="">
        <p className={`text-end day ${getCurrentDayClass()}`}>
          {day.format("DD")}
        </p>
      </div>
      <div className="cursor-pointer">
        {getDaySchedules(schedules, day)?.map((evt, idx) => (
          <Events key={idx} event={evt} isCurrentDay={getCurrentDayClass()} />
        ))}
      </div>
    </td>
  );
}
