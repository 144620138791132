import React, { useState } from "react";

import { useMutation, useQueryClient } from "react-query";
import { parseISO, formatDistanceToNow } from "date-fns";

import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { FlexBox } from "components/reusables/FlexBox";
import { markMessageAsRead } from "api/messaging";

import { ReactComponent as Mail } from "assets/images/icons/Vector(12).svg";
import { ReactComponent as Phone } from "assets/images/icons/Vector(13).svg";
import { ReactComponent as Eye } from "assets/images/icons/Vector(15).svg";
import { ReactComponent as Share } from "assets/images/icons/Vector(16).svg";
import {
  SingleMessageWrapper,
  CtaButtons,
} from "./styles/styled.signleMesssage";

const convertDate = (date) => {
  const newDate = parseISO(date);
  const timePeriod = formatDistanceToNow(newDate);
  return timePeriod;
};

const SingleMessage = ({ message, close }) => {
  const [action, setAction] = useState("");
  const queryClient = useQueryClient();

  const markAsReadAndRepliedMutation = useMutation(markMessageAsRead, {
    onSuccess: () => {
      close();
      queryClient.refetchQueries("get-message");
      toastSuccess("Status updated successfully");
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const unSeeHandler = () => {
    setAction("read");
    markAsReadAndRepliedMutation.mutateAsync({
      id: message._id,
      read: !message.read,
    });
  };

  const markAsRepliedHandler = () => {
    setAction("replied");
    markAsReadAndRepliedMutation.mutateAsync({
      id: message._id,
      replied: !message?.replied,
    });
  };

  return (
    <SingleMessageWrapper>
      <FlexBox className="mb-3">
        <p className="username">
          {message?.firstName} {message?.lastName}
        </p>
        <p className="time">{convertDate(message?.createdAt)} </p>
      </FlexBox>
      <div className="d-flex gap-3 mb-3">
        <Mail />
        <p className="email">{message?.email}</p>
      </div>

      <div className="d-flex gap-3">
        <Phone />
        <p className="email">{message?.phoneNumber}</p>
      </div>
      <div className="message-content">{message?.message}</div>
      <FlexBox className="buttons gap-2" justify="flex-end">
        <CtaButtons
          hasBorder
          background="#FFFFFF"
          color="#979797"
          onClick={unSeeHandler}
          disabled={markAsReadAndRepliedMutation.isLoading}
        >
          {" "}
          <Eye />{" "}
          {markAsReadAndRepliedMutation.isLoading && action === "read"
            ? "Loading..."
            : `${message?.read ? "Unsee" : "See"}`}
        </CtaButtons>
        <CtaButtons
          background="#17568B"
          color="white"
          onClick={markAsRepliedHandler}
          disabled={markAsReadAndRepliedMutation.isLoading}
        >
          <Share />
          {markAsReadAndRepliedMutation.isLoading && action === "replied"
            ? "Loading..."
            : `Mark as
          ${message?.replied ? "UnReplied" : "Replied"}`}
        </CtaButtons>
      </FlexBox>
    </SingleMessageWrapper>
  );
};

export default SingleMessage;
