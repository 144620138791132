import { CardOverlay } from "components/reusables/FlexBox";
import styledComponents from "styled-components";

export const NotificationsWrapper = styledComponents(CardOverlay)`
height: 401px;
overflow-y: scroll;
.heading{
  font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 157%;
color: #666666;
}
.noitifications{
    .notification{
        border-left : 2px solid #509FEF;
        padding-left : 6px;
        margin : 10px 0;
        .message{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #050C12;
        }
        .sender{
        font-style : italic;
        }
    }
}
`;
