import React from "react";

import { useFormik } from "formik";
import { useMutation } from "react-query";

import { createDepartment } from "api/career";
import Button from "components/reusables/Button";
import CustomInputBox from "./Input";
import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";

import { AddCategoryWrapper } from "./styles/styled.addCategory";

const AddCategory = ({ close }) => {
  const createDepartmentMutation = useMutation(createDepartment, {
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
    onSuccess: () => {
      close();
      toastSuccess("Department added successfully");
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: handleSubmit,
    validate: (values) => {
      if (!values.name)
        return {
          name: "Enter a valid name",
        };
    },
  });

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  function handleSubmit(values) {
    createDepartmentMutation.mutateAsync(values);
  }

  return (
    <AddCategoryWrapper>
      <h1>Add New Category</h1>
      <div className="input">
        <CustomInputBox
          name="name"
          label="Category Name *"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={hasErrors("name")}
          errorMessage={formik.errors.name}
        />
      </div>
      <Button
        type="submit"
        onClick={formik.handleSubmit}
        disabled={createDepartmentMutation.isLoading}
      >
        {createDepartmentMutation.isLoading ? "Loading.." : "Add Category"}
      </Button>
    </AddCategoryWrapper>
  );
};

export default AddCategory;
