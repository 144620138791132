import React from "react";
import { parseISO, formatDistanceToNow } from "date-fns";

import { FlexBox } from "components/reusables/FlexBox";
import { ApplicantsWrapper } from "./styles/styled.applicants";

const Applicants = ({ data }) => {
  const convertDate = (date) => {
    if (!date) {
      return;
    }
    const newDate = parseISO(date);
    const timePeriod = formatDistanceToNow(newDate);
    return timePeriod;
  };

  return (
    <ApplicantsWrapper>
      <FlexBox align="flex-start">
        <div className="first_name">
          <p className="key">First name</p>
          <p className="value">{data?.firstName}</p>
        </div>
        <div className="surname">
          <p className="key">Surname</p>
          <p className="value">{data?.lastName}</p>
        </div>
        <div className="email">
          <p className="key">Email Address</p>
          <p className="value">{data?.email}</p>
        </div>
        <div className="linkedin">
          <p className="key">LinkedIn URL</p>
          <a
            id="link"
            target="_blank"
            href={data?.linkedInURL}
            className="value"
            rel="noreferrer"
          >
            View
          </a>
        </div>
        <div className="time">
          <p className="time-key">{convertDate(data?.createdAt)}</p>
        </div>
      </FlexBox>
      <FlexBox align="flex-start" className="others">
        <div className="phone">
          <p className="key">Phone Number</p>
          <p className="value">{data?.phoneNumber}</p>
        </div>
        <div className="surname">
          <p className="key">Location</p>
          <p className="value">{data?.location}</p>
        </div>
        <div className="email">
          <p className="key">Resume</p>
          <a
            id="link"
            target="_blank"
            href={data?.resumeURL}
            download
            className="value"
            rel="noreferrer"
          >
            View Resume
          </a>
        </div>
      </FlexBox>
    </ApplicantsWrapper>
  );
};

export default Applicants;
