import styledComponents from "styled-components";
import { FlexBox } from "components/reusables/FlexBox";
import { fonts } from "components/constants";

export const CalendarHeaderWrapper = styledComponents(FlexBox)`
.todays_date{
    font-weight: 500;
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
    font-family : ${fonts.giloryMedium}
}
`;
