import React, { useState } from "react";

import { useQuery } from "react-query";

import { FlexBox } from "components/reusables/FlexBox";
import MessageCard from "./MessageCard";
import { getMessages } from "api/messaging";
import LoadingSpinner from "components/reusables/LoadingSpinner";

import { MessagingWrapper } from "./styles/styled.messaging";
import EmptyData from "utils/EmptyData/EmptyData";

const Messaging = () => {
  const [active, setActive] = useState("");
  const handleChange = (e) => {
    setActive(e.target.value);
  };

  const { isLoading, data } = useQuery(
    [
      "get-message",
      {
        read: active === "read" || active === "read_replied",
        replied: active === "replied" || active === "read_replied",
      },
    ],
    getMessages
  );

  if (isLoading) return <LoadingSpinner />;

  return (
    <MessagingWrapper>
      <FlexBox>
        <h1 className="heading">Messaging</h1>
      </FlexBox>
      <label htmlFor="role_status" className="label">
        Filter by role status
      </label>
      <select
        name="role_status"
        id="role_status"
        value={active}
        onChange={handleChange}
        required
      >
        <option value="">All</option>
        <option value="read">Read</option>
        <option value="replied">Replied</option>
        <option value="read_replied">Read and Replied</option>
      </select>

      {data?.data?.length === 0 ? (
        <EmptyData text="No messages" />
      ) : (
        <FlexBox className="message_card_container">
          {data?.data?.map((message) => (
            <MessageCard key={message._id} message={message} />
          ))}
        </FlexBox>
      )}
    </MessagingWrapper>
  );
};

export default Messaging;
