import React, { useState } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { useMutation, useQuery, useQueryClient } from "react-query";

import Modal from "components/reusables/Modal";
import {
  apiErrorHandler,
  capitalizeWord,
  toastError,
  toastSuccess,
} from "utils/helpers";
import Applicants from "./Applicants";
import { changeRoleStatus, getSingleApplicant } from "api/career";
import { FlexBox } from "components/reusables/FlexBox";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import { ReactComponent as MenuIcon } from "assets/images/icons/Vector(17).svg";

import "rc-dropdown/assets/index.css";
import { CardWrapper } from "./styles/styled.card";

const Card = ({ title, data }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const changeStatusMutation = useMutation(changeRoleStatus, {
    onSuccess: () => {
      queryClient.refetchQueries("get-open-roles");
      toggleModal();
      toastSuccess("Role updated successfully");
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const { isLoading, data: applicantsData } = useQuery(
    [`get-applicants-${data?._id}`, { id: data?._id }],
    getSingleApplicant,
    {
      enabled: open,
    }
  );

  function toggleModal() {
    setOpen(!open);
  }

  const menu = (
    <Menu>
      <MenuItem onClick={handleClick} disabled={changeStatusMutation.isLoading}>
        {changeStatusMutation.isLoading
          ? "Loading..."
          : `Make ${!data?.active ? "Active" : "Inactive"}`}
      </MenuItem>
    </Menu>
  );

  function handleClick() {
    const payload = {
      active: !data?.active,
      id: data?._id,
    };

    changeStatusMutation.mutateAsync(payload);
  }

  return (
    <CardWrapper>
      <h3>{title}</h3>
      <div className="applicants-cta" onClick={toggleModal}>
        View Applicants
      </div>

      {open && (
        <Modal close={toggleModal}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <FlexBox className="modal-opening-content">
                <div className="left">
                  <div className="job_title">
                    <h4>{data.title}</h4>
                    <div>
                      <span className="dept">{data?.department}</span>
                      <span>{capitalizeWord(data?.type)}</span>
                      <span>{capitalizeWord(data?.nature)}</span>
                    </div>
                  </div>
                </div>
                <div className="category_cta d-flex gap-4 align-items-center">
                  <button className="d-flex gap-2 align-items-center justify-content-around">
                    <span className="dot "></span>{" "}
                    {data?.active ? "Active" : "In Active"}
                  </button>
                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    animation="slide-up"
                  >
                    <MenuIcon className="menu-icon" />
                  </Dropdown>
                </div>
              </FlexBox>
              <div className="applicants">
                {applicantsData?.data?.map((applicant) => (
                  <Applicants key={applicant._id} data={applicant} />
                ))}
              </div>

              {applicantsData?.data?.length === 0 && (
                <div className="empty_state">
                  No applicants yet, check back later
                </div>
              )}
            </>
          )}
        </Modal>
      )}
    </CardWrapper>
  );
};

export default Card;
