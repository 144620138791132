import * as Yup from "yup";

export const VALIDATION_SCHEMA_ADD_OPENING = Yup.object().shape({
  department: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  summary: Yup.string().required("Required"),
  nature: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  about: Yup.string().required("Required"),
  responsibility: Yup.string().required("Required"),
  coreSkills: Yup.string().required("Required"),
  managementSkills: Yup.string().required("Required"),
  desiredSkills: Yup.string().required("Required"),
  aboutRole: Yup.string(),
});
