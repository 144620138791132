export const access_levels = [
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "operations",
    label: "Operations",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export const ADD_ADMIN_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  userRole: "",
  password: "",
};

export const obj = {
  meetingTime: "9:00 AM",
  name: "John Doe",
  email: "email@e.com",
};
