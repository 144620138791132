import moment from "moment";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export const getError = (error) => {
  const errorMessage =
    error && error.message
      ? titleCase(error.message?.replace(/[^a-z0-9+]+/gi, " "))
      : "Error Processing Request. Try again.";

  return errorMessage;
};

export const truncate = (str, len = 50) => {
  if (str?.length > len) {
    return str?.substring(0, len) + "...";
  } else {
    return str;
  }
};

export const titleCase = (string) => {
  if (string === null || string === undefined) {
    return "";
  } else {
    let text = string.toLowerCase().split("_");
    for (var i = 0; i < text.length; i++) {
      text[i] = text[i][0]?.toUpperCase() + text[i]?.slice(1);
    }
    return text.join(" ");
  }
};

export const toCurrency = (n) => {
  if (n === null || n === undefined || n === "NaN") return 0;
  return Number(n)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
};

export const timeDateFormat = (obj, format = "YYYY-MM-DD") => {
  if (!obj || obj === undefined) return "";

  const timeAgo = moment(obj).fromNow();

  const newObj = new Date(obj);

  const fullTime = moment(newObj).format("hh:mm:ss a");
  const time = moment(newObj).format("hh:mm:ss");
  const date = moment(newObj).format(format);

  return { time, date, fullTime, timeAgo };
};

export const timeRangeFormat = (obj, init) => {
  if (!obj) return null;

  const newDate = new Date(obj);
  init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
  return newDate;
};

export const dateToString = (obj) => {
  if (!obj) return null;

  const newDate = new Date(obj);
  const date = moment(newDate).format("LL");
  return date;
};

export const reducedCategories = (data) => {
  const newData = data?.reduce((acc, curr) => {
    const { department } = curr;
    let a = {
      ...acc,
      [department]: acc[department] ? [...acc[department], curr] : [curr],
    };
    return a;
  }, {});

  return newData;
};

export const apiErrorHandler = (error) => {
  try {
    if (!error.response) {
      return "Error: Network Error";
    } else if (error.response.data) {
      const erd = error.response.data;
      if (erd.detail) {
        if (erd.detail === "Invalid token.") {
          return "Invalid Token. Please re-login.";
        }
        return erd.detail;
      }
      if (erd[0]) return erd[0];
      const key = Object.keys(erd)[0];
      const errorString = erd[key][0];
      if (["non_field_errors", "detail"].indexOf(key) >= 0) {
        return errorString;
      }
      const errorField = String(key).replace("_", " ");
      return (
        errorField.charAt(0).toUpperCase() +
        errorField.substring(1) +
        ": " +
        errorString
      );
    }
  } catch (error) {
    console.log(error);
    return "Something wrong happened. Please report this.";
  }
};
export const capitalizeWord = (word) => {
  return word[0].toUpperCase() + word.substring(1);
};

export const colors = [
  { color: "#63B67F", bgColor: "#D7F2E4" },
  { color: "#4C9BDD", bgColor: "#C4EAFF" },
  { color: "#A5A6F6", bgColor: "#FBEBFF" },
  { color: "#97620D", bgColor: "#FDEED6" },
];

export function getRandomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export function selectWeek(date) {
  return Array(7)
    .fill(new Date(date))
    .map((el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx)));
}

export function getISOWeek(weekNumber) {
  const [w, y] = weekNumber.split(" ").map((n) => parseInt(n));
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  const temp = {
    d: ISOweekStart.getDate(),
    m: ISOweekStart.getMonth(),
    y: ISOweekStart.getFullYear(),
  };
  const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate();
  return Array.from({ length: 7 }, (_) => {
    if (temp.d > numDaysInMonth) {
      temp.m += 1;
      temp.d = 1;
    }
    return new Date(temp.y, temp.m, temp.d++);
  });
}

export const toastError = (message) => {
  toast.dismiss();
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastSuccess = (message) => {
  toast.dismiss();
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const hasErrors = (formik, key) => {
  return formik?.touched?.[key] && !!formik?.errors?.[key]
    ? formik?.errors?.[key]
    : null;
};
