export const responseStatus = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  EMPTY: "EMPTY",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};

export const fonts = {
  consolasRegular: "consolas-regular",
  giloryRegular: "gilroy-regular",
  giloryMedium: "gilroy-medium",
  giloryBold: "gilroy-bold",
  gilorySemiBold: "gilroy-semibold",
  giloryRegularItalic: "gilroy-regular-italic",
};

export const STEPS = {
  STEP_1: 1,
  STEP_2: 2,
};
