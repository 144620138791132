import React from "react";
import CustomPassword from "utils/CustomInputBox/CustomPassword";
import { STEPS } from "components/constants";

const ResetPasswordForm = ({ setStep }) => {
  return (
    <>
      <div className="greeting">
        <p>Reset Password</p>
      </div>
      <CustomPassword
        label="New Password"
        name="password"
        placeholder="Enter your password"
      />
      <CustomPassword
        label="Confirm Password"
        name="confirmPassword"
        placeholder="Enter your password"
      />

      <button className="" onClick={() => setStep(STEPS.STEP_2)}>
        Save Password
      </button>
    </>
  );
};

export default ResetPasswordForm;
