import React, { useState } from "react";

import Modal from "components/reusables/Modal";
import SingleSchedule from "./SingleSchedule";
import { BoxWrapper } from "./styles/styled.box";

const Box = ({ isBooked, isFixed, day }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (isBooked) {
      return;
    }
    toggleOpen();
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <BoxWrapper
        bg={isBooked || isFixed ? "#E0FEE7" : "#FFF7F7"}
        color={isBooked || isFixed ? "#90BFAC" : "#F9CFCF"}
        className="flex__center"
        onClick={handleClick}
        cursor={isBooked ? "not-allowed" : "pointer"}
      >
        {isBooked ? "Booked" : isFixed ? "Fixed" : "Free"}
      </BoxWrapper>
      {open && (
        <Modal close={toggleOpen} width="450px">
          <SingleSchedule close={toggleOpen} day={day} isFixed={isFixed} />
        </Modal>
      )}
    </>
  );
};

export default Box;
