import React, { useState } from "react";
import { useQuery } from "react-query";

import { getMonth } from "utils/helpers/index";
import FullMonthView from "./FullMonthView";
import { getMonthsSchedule } from "api/calendar";
import LoadingSpinner from "components/reusables/LoadingSpinner";

import { MonthWrapper } from "../styles/styled.month";

const Month = () => {
  const [currentMonth] = useState(getMonth());
  const { isLoading, data } = useQuery(
    "get-monthly-schedule",
    getMonthsSchedule
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <MonthWrapper>
      <table className="mb-2">
        <thead>
          <tr className="thead-row">
            {currentMonth[0].map((day, i) => (
              <th key={i} scope="col">
                <span className="d-block text-center weekdays-title">
                  {day.format("dddd")}
                </span>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <table>
        <tbody>
          {currentMonth.map((row, i) => (
            <tr key={i} className="thead-row">
              {row.map((day, idx) => (
                <FullMonthView
                  day={day}
                  key={idx}
                  rowIdx={i}
                  schedules={data?.data}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </MonthWrapper>
  );
};

export default Month;
