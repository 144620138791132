import { FlexBox } from "components/reusables/FlexBox";
import { AuthLayoutStyles } from "./AuthLayoutStyles";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";

const AuthLayout = ({ children }) => {
  return (
    <AuthLayoutStyles>
      <FlexBox className="login-header">
        <Logo />
      </FlexBox>
      <div className="form-container">{children}</div>
      <p className="footer-text">
        This dashboard is private to blusalt emplyees only, and should <br />{" "}
        not be used by members of the public
      </p>
    </AuthLayoutStyles>
  );
};

export default AuthLayout;
