import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import { FlexBox } from "components/reusables/FlexBox";
import { format } from "../Calender/data";
import { getMonth } from "utils/helpers/index";

import { MonthViewWrapper } from "./styles/styled.monthview";

export default function MonthView({ currentWeek }) {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  function isWeekend(day) {
    if (
      day.format("ddd").toUpperCase() === "SUN" ||
      day.format("ddd").toUpperCase() === "SAT"
    ) {
      return "weekend";
    }
    return "";
  }

  const getActiveWeek = (week) => {
    let formattedWeek = week[1].format(format);
    let formattedCurrentWeek = dayjs(currentWeek[1]).format(format);
    if (formattedWeek === formattedCurrentWeek) return "active_week";
    return "";
  };

  return (
    <MonthViewWrapper>
      <FlexBox className="month_header">
        <div onClick={handlePrevMonth}>
          <BsChevronLeft />
        </div>
        <p className="mb-0">
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM")}
        </p>
        <div onClick={handleNextMonth}>
          <BsChevronRight />
        </div>
      </FlexBox>
      <table className="">
        <thead className="">
          <tr>
            {currentMonth[0].map((day, i) => (
              <th key={i} scope="col">
                <span className="d-block text-center weekdays-title">
                  {day.format("ddd").toUpperCase()}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="days">
          {currentMonth.map((row, i) => (
            <tr key={i} className={`${getActiveWeek(row)}`}>
              {row.map((day, idx) => (
                <td key={idx} className={`${isWeekend(day)}`}>
                  <span className="">{day.format("D")}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </MonthViewWrapper>
  );
}
