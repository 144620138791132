import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const WeekWrapper = styledComponents.div`
 table {
    width: 100%;
  }
    .thead-row{
    display: flex;
    justify-content: space-between;
    th{
        flex: 1;
    }
  }
    .weekdays-title{
    font-weight: 500;
    font-size: 13px;
    line-height: 157%;
    font-family : ${fonts.giloryMedium};
    color: rgba(23, 86, 139, 0.5);
  }
    td{
      border: 1px solid #EEF4FF;
      height : 135px;
      flex: 1;
      overflow-y: scroll;
  }
  .time{
    font-weight: 500;
    font-size: 11px;
    line-height: 157%;
    color: #666666;
    font-family : ${fonts.giloryMedium};
    padding-right: 10px;
  }
  .event{
    padding : 8px 11px;
    .name{
        font-weight: 500;
        font-size: 12px;
        line-height: 157%;
        color: #101E2D;
        margin : 0;
         font-family : ${fonts.giloryMedium};
    }
    .email{
        font-weight: 500;
        font-size: 12px;
        line-height: 157%;
        color: rgba(16, 30, 45, 0.42);
        margin : 2px 0 0;
        font-family : ${fonts.giloryMedium};
    }
  }
`;
