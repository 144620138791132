import React, { useState } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { useQuery } from "react-query";

import Card from "./Card";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import { getDepartments, getOpenRoles } from "api/career";
import { reducedCategories } from "utils/helpers";
import AddNewOpening from "./AddNewOpening";
import Modal from "components/reusables/Modal";
import AddCategory from "./AddCategory";
import Button from "components/reusables/Button";
import { FlexBox } from "components/reusables/FlexBox";
import DeleteCategory from "./DeleteCategory";
import { ReactComponent as MenuIcon } from "assets/images/icons/Vector(17).svg";

import { CareerStyles } from "./styled.careers";
import "rc-dropdown/assets/index.css";
import EmptyData from "utils/EmptyData/EmptyData";

const Careers = () => {
  const [active, setActive] = useState("active");
  const [key, setKey] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { data: departments, isLoading: departmentLoading } = useQuery(
    "get-departments",
    getDepartments
  );
  const { isLoading, data } = useQuery(
    ["get-open-roles", { active: active === "active" ? true : false }],
    getOpenRoles,
    {}
  );

  const [modalState, setModalState] = useState({
    addNewOpening: false,
    addCategory: false,
  });

  if (isLoading || departmentLoading) {
    return <LoadingSpinner />;
  }
  const arr = reducedCategories(data?.data);
  const arrOfKeys = Object.keys(arr);

  const toggleModalState = (key) => {
    setModalState({
      ...modalState,
      [key]: !modalState[key],
    });
  };

  const handleChange = (e) => {
    setActive(e.target.value);
  };

  const menu = (
    <Menu onSelect={onSelect}>
      <MenuItem key="1">Delete category</MenuItem>
    </Menu>
  );

  function onSelect({ key }) {
    setKey(key);
  }

  function handleClose() {
    setKey("");
    setSelectedCategory("");
  }

  return (
    <CareerStyles className="">
      <FlexBox className="career-heading">
        <h1 className="heading">Careers</h1>
        <div className="cta-buttons d-flex gap-3">
          <Button onClick={() => toggleModalState("addCategory")}>
            Add Category
          </Button>
          <Button onClick={() => toggleModalState("addNewOpening")}>
            Create New Role
          </Button>
        </div>
      </FlexBox>
      <label htmlFor="role_status" className="label">
        Filter by role status
      </label>
      <select
        name="role_status"
        id="role_status"
        value={active}
        onChange={handleChange}
        required
      >
        <option value="active">Active</option>
        <option value="inactive">In Active</option>
      </select>
      {arrOfKeys?.length === 0 ? (
        <EmptyData text="No applications" />
      ) : (
        <div className="careeer-content">
          {arrOfKeys?.map((keys) => (
            <div className="category" key={keys}>
              <FlexBox className="career_heading">
                <div className="category_name">{keys}</div>
                <div className="category_cta d-flex gap-4 align-items-center">
                  <button className="d-flex gap-2 align-items-center justify-content-around">
                    <span className="dot "></span>{" "}
                    {arr[keys][0]?.active ? "Active" : "In Active"}
                  </button>

                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    animation="slide-up"
                  >
                    <MenuIcon
                      className="menu-icon"
                      onClick={() => setSelectedCategory(keys)}
                    />
                  </Dropdown>
                </div>
              </FlexBox>
              <FlexBox justify="normal" className="category-jobs">
                {arr[keys]?.map((data) => (
                  <Card key={data?._id} title={data?.title} data={data} />
                ))}
              </FlexBox>
            </div>
          ))}
        </div>
      )}

      {modalState.addNewOpening && (
        <Modal
          width="600px"
          height="700px"
          close={() => toggleModalState("addNewOpening")}
        >
          <AddNewOpening
            close={() => toggleModalState("addNewOpening")}
            data={departments}
          />
        </Modal>
      )}
      {modalState.addCategory && (
        <Modal width="500px" close={() => toggleModalState("addCategory")}>
          <AddCategory close={() => toggleModalState("addCategory")} />
        </Modal>
      )}

      {key === "1" && (
        <Modal width="500px" close={handleClose}>
          <DeleteCategory
            close={handleClose}
            departments={departments?.data}
            selectedCategory={selectedCategory}
          />
        </Modal>
      )}
    </CareerStyles>
  );
};

export default Careers;
