import React, { useState } from "react";
import { useQuery } from "react-query";

import { FlexBox } from "components/reusables/FlexBox";
import Button from "components/reusables/Button";
import SingleAdminCard from "./SingleAdminCard";
import Modal from "components/reusables/Modal";
import AddAdmin from "./AddAdmin";
import { getAdmin } from "api/admin";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import { AdminWrapper } from "./styles/admin.styled";

const Admin = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const { data, isLoading } = useQuery("get-all-users", getAdmin);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AdminWrapper>
      <FlexBox>
        <h1 className="heading">Admin</h1>
        <Button onClick={toggleOpen}>Add new</Button>
      </FlexBox>

      <FlexBox className="admins-container" justify="normal">
        {data?.data.map((item) => (
          <SingleAdminCard data={item} key={item._id} />
        ))}
      </FlexBox>
      {open && (
        <Modal close={toggleOpen} width="508px">
          <AddAdmin close={toggleOpen} />
        </Modal>
      )}
    </AdminWrapper>
  );
};

export default Admin;
