import * as Yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email("Invalid email.").required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});
