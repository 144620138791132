import React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout.svg";
import getRoutes from "../routes/routes";
import Button from "components/reusables/Button";
import { deleteUserData } from "storage/sessionStorage";

const Sidebar = ({ location: { pathname } }) => {
  const role = localStorage.getItem("role");
  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    deleteUserData();
    history.push("/");
  };
  return (
    <section className="sidebar">
      <nav className="sidebar__nav">
        <div className="flex__center mb-5">
          <Logo />
        </div>

        <ul className="list-unstyled" id="sidebar__nav">
          {getRoutes(role).map(
            (route, i) =>
              !route?.disabled && (
                <li key={i} className="sidebar__nav__item">
                  <Link
                    to={route.path}
                    className="sidebar__nav__item__link"
                    data-active={route.path === pathname}
                  >
                    {route.Icon ? <route.Icon /> : ""}
                    <p className="mt-2">{route.name}</p>
                  </Link>
                </li>
              )
          )}
        </ul>
        <Button className="ms-4" onClick={handleLogout}>
          <LogoutIcon className="me-2" /> Logout
        </Button>
      </nav>
    </section>
  );
};

export default withRouter(Sidebar);
