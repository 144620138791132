import styledComponents from "styled-components";

export const EventsWrapper = styledComponents.div`
p{
font-weight: 400;
font-size: 12px;
color: #666666;
padding : 3px;
margin : 0;
}
`;
