import React, { useState } from "react";

import CalendarHeader from "./CalendarHeader";
import Day from "./Day/Day";
import Month from "./Month/Month";
import Week from "./Week/Week";
import dayjs from "dayjs";
import { CalenderWrapper } from "./styles/styled.calendar";
import { format } from "./data";

const Calender = () => {
  const [active, setActive] = useState("day");
  const [daySelected, setDaySelected] = useState(dayjs().format(format));

  const toggleActiveChange = (val) => {
    setActive(val);
  };

  const content = {
    day: <Day setDaySelected={setDaySelected} daySelected={daySelected} />,
    week: <Week />,
    month: <Month />,
  };

  return (
    <CalenderWrapper>
      <CalendarHeader
        active={active}
        toggleActiveChange={toggleActiveChange}
        daySelected={daySelected}
      />
      <div className="content">{content[active]}</div>
    </CalenderWrapper>
  );
};

export default Calender;
