import React from "react";

import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";

import { apiErrorHandler, toastError, toastSuccess } from "utils/helpers";
import { createAvailability, removeAvailability } from "api/calendar";
import { FlexBox } from "components/reusables/FlexBox";
import Button from "components/reusables/Button";

import { SingleScheduleWrapper } from "./styles/styled.singleSchedule";

const SingleSchedule = ({ close, day, isFixed }) => {
  const queryClient = useQueryClient();
  const date = dayjs(day?.weekDay).format("YYYY-MM-DD");

  const apiToCall = isFixed ? removeAvailability : createAvailability;

  const createPayload = {
    availability: [
      {
        date,
        time: [day?.time],
      },
    ],
  };

  const unschedulePayload = {
    date,
    time: day?.time,
  };

  const createScheduleMutation = useMutation(apiToCall, {
    onSuccess: () => {
      queryClient.refetchQueries("get-week-schedule");
      queryClient.refetchQueries("get-availability");
      close();
      toastSuccess("Schedule updated successfully");
    },
    onError: (err) => {
      let error = err.response.data.message || apiErrorHandler(err);
      toastError(error);
    },
  });

  const handleDelete = () => {
    const payload = isFixed ? unschedulePayload : createPayload;
    createScheduleMutation.mutateAsync(payload);
  };

  return (
    <SingleScheduleWrapper>
      <p>
        Are you sure you want to {isFixed ? "unschedule" : "schedule"} this time
        ?
      </p>
      <p className="date">
        {" "}
        ({dayjs(day?.weekDay).format("dddd, DD MMMM YYYY")}, {day?.time})
      </p>
      <FlexBox direction="row" justifyContent="center" mb="1rem">
        <Button className="no" variant="contained" onClick={close}>
          No
        </Button>
        <Button
          className="yes"
          variant="contained"
          onClick={handleDelete}
          disabled={createScheduleMutation.isLoading}
        >
          {createScheduleMutation.isLoading
            ? "Loading..."
            : isFixed
            ? "Unschedule"
            : "Schedule"}
        </Button>
      </FlexBox>
    </SingleScheduleWrapper>
  );
};

export default SingleSchedule;
