import React from "react";
import { useQuery } from "react-query";

import { FlexBox } from "components/reusables/FlexBox";
import { Analytics } from "./Analytics";
import UpcomingSchedule from "./UpcomingSchedule";
import Notifications from "./Notifications";
import User from "./User";
import { getDashboardData } from "api/dashboard";
import LoadingSpinner from "components/reusables/LoadingSpinner";

import { DashboardStyles } from "./styled.dashboard";

const DashContent = () => {
  const { isLoading, data } = useQuery("dashboard-data", getDashboardData);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <DashboardStyles>
      <h1 className="heading">Dashboard</h1>
      <FlexBox className="dash-container" align="flex-start">
        <div className="right-side">
          <Analytics data={data?.data} />
          <UpcomingSchedule schedules={data?.data?.upcomingSchedules} />
        </div>
        <div className="left-side">
          <Notifications data={data?.data?.notifications} />
          <User data={data?.data?.admin[0]} />
        </div>
      </FlexBox>
    </DashboardStyles>
  );
};

export default DashContent;
