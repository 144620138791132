import styledComponents from "styled-components";
import { fonts } from "components/constants";
import { CardOverlay } from "components/reusables/FlexBox";

export const CardWrapper = styledComponents(CardOverlay)`
padding : 14px 21px;
box-shadow: 2px 2px 32px 8px rgba(29, 91, 154, 0.08);
width : 15%;
.applicants-cta{
  background: #F4F8FF;
  border: 1px solid rgba(80, 159, 239, 0.11);
  border-radius: 2px;
  height: 26px;
  font-size: 13px;
  line-height: 125%;
  color: #56A3ED;
  font-family : ${fonts.gilorySemiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding : 5px;
  cursor : pointer;
}
h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: #666666;
     font-family : ${fonts.gilorySemiBold};
}
.modal-opening-content{
    margin-top : 36px;
}
.left{
    h4{
        font-weight: 600;
        font-size: 14px;
        line-height: 147%;
        color: #101E2D;
         font-family : ${fonts.gilorySemiBold};
    }
    span{
        font-size: 12px;
        line-height: 69%;
        color: rgba(16, 30, 45, 0.43);
        font-family : ${fonts.gilorySemiBold};
            margin: 0  10px 0 0;
    }
}
.category_cta{
   button{
        background: #FAFBFD;
        padding: 7px 15px;
        height: 32px;
        width: 99px;
        font-size: 14px;
        line-height: 125%;
        color: #101E2D;
        font-family : ${fonts.giloryMedium};
        .dot{
            width: 9px;
            height: 9px;
            background: #63D74E;
            border-radius: 50px;
        }
   }
}
.empty_state{
    margin : 40px 0;
    text-align : center;
    font-family : ${fonts.giloryBold};
    font-size : 20px;
}
  .menu-icon{
      cursor: pointer;
  }

      @media (max-width: 576px) {
        width : 100%;
      }
`;
