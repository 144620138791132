import axios from "axios";
import { fetchUserData } from "storage/sessionStorage";

const CancelToken = axios.CancelToken;
let requestSignal;

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

API.interceptors.request.use((config) => {
  requestSignal = CancelToken.source();
  config.cancelToken = requestSignal.token;

  const accessToken = fetchUserData();
  config.headers = {
    Authorization: `${accessToken}`,
  };

  return config;
});

API.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const status = error.response.status;

    console.log(error.response);
    if (
      status === 401 ||
      error.response.message === "Auth failed! jwt expired"
    ) {
      // Perform UnAuthorized action here
      window.location = "/";
      sessionStorage.clear();
      localStorage.clear();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export { API as default, requestSignal };
