import Admin from "components/Dashboard/Admin/Admin";
import Calender from "components/Dashboard/Calender/Calender";
import Careers from "components/Dashboard/Careers/Careers";
import DashContent from "components/Dashboard/DashContent/DashContent";
import Messaging from "components/Dashboard/Messaging/Messaging";
import Schedule from "components/Dashboard/Schedule/Schedule";
import { ReactComponent as DashIcon } from "assets/images/icons/dashboard.svg";
import { ReactComponent as ScheduleIcon } from "assets/images/icons/schedule.svg";
import { ReactComponent as CalenderIcon } from "assets/images/icons/calender.svg";
import { ReactComponent as MessageIcon } from "assets/images/icons/messaging.svg";
import { ReactComponent as CareerIcon } from "assets/images/icons/career.svg";
import { ReactComponent as AdminIcon } from "assets/images/icons/admin.svg";
import { ADMIN, OPERATIONS, SALES } from "../roles";

const getRoutes = (role) => {
  return [
    {
      name: "Dashboard",
      path: "/",
      component: DashContent,
      Icon: DashIcon,
      exact: true,
      disabled: false,
    },
    {
      name: "Schedule",
      path: "/schedule",
      component: Schedule,
      Icon: ScheduleIcon,
      exact: true,
      disabled: role === ADMIN || role === SALES ? false : true,
    },
    {
      name: "Calender",
      path: "/calender",
      component: Calender,
      Icon: CalenderIcon,
      exact: true,
      disabled: role === ADMIN || role === SALES ? false : true,
    },
    {
      name: "Messaging",
      path: "/messaging",
      component: Messaging,
      Icon: MessageIcon,
      exact: true,
      disabled: role === ADMIN || role === SALES ? false : true,
    },
    {
      name: "Careers",
      path: "/careers",
      component: Careers,
      Icon: CareerIcon,
      exact: true,
      disabled: role === ADMIN || role === OPERATIONS ? false : true,
    },
    {
      name: "Admin",
      path: "/admin",
      component: Admin,
      Icon: AdminIcon,
      exact: true,
      disabled: role !== ADMIN,
    },
  ];
};
export default getRoutes;
