import styledComponents from "styled-components";
import { fonts } from "components/constants";

export const ApplicantsWrapper = styledComponents.div`
background: #F7FCFF;
border-radius: 2px;
padding : 15px 17px;
margin : 18px 0 ;
.first_name,
.surname,
.email,
.time{
flex : 0.6;
}
.linkedin{
flex : 2;
}
.phone {
    flex : 0.7;
}
.key{
    font-weight: 400;
    font-size: 11.2063px;
    line-height: 13px;
    color: #101E2D;
    font-family : ${fonts.gilorySemiBold};
    margin-bottom : 5px;
}
.value{
    font-weight: 400;
    font-size: 11.2063px;
    line-height: 13px;
    color: rgba(10, 58, 100, 0.4);
    font-family : ${fonts.gilorySemiBold};
}
.time-key{
    font-weight: 600;
    font-size: 12px;
    line-height: 69%;
    color: rgba(16, 30, 45, 0.43);
    font-family : ${fonts.gilorySemiBold};
}
.others{
    width : 50%;
    margin-top : 1px;
}
#link{
    color: #509FEF;
    font-weight: 400;
    font-size: 11.2063px;
    line-height: 13px;
}
`;
