import { fonts } from "components/constants";
import styledComponents from "styled-components";

export const CareerStyles = styledComponents.div`
margin-top : 48px;
padding : 0 27px;
.heading{
    font-family : ${fonts.giloryMedium};
    font-size: 24px;
    line-height: 157%;
    color: #101E2D;
}
.careeer-content{
    margin-top : 33px;
}
.career_heading{
    background: #FFFFFF;
    border: 1px solid #EEF4FF;
    padding : 10px 21px 10px 15px;
}
.category_name{
    font-weight: 600;
    font-size: 14px;
    line-height: 157%;
    color: #050C12;
     font-family : ${fonts.gilorySemiBold};
}
.category_cta{
   button{
        background: #FAFBFD;
        padding: 7px 15px;
        height: 32px;
        width: 99px;
        font-size: 14px;
        line-height: 125%;
        color: #101E2D;
        font-family : ${fonts.giloryMedium};
        .dot{
            width: 9px;
            height: 9px;
            background: #63D74E;
            border-radius: 50px;
        }
   }
}
.category-jobs{
   flex-wrap: wrap;
   gap : 18px; 
   margin-top : 32px;
}
.category{
  margin : 33px 0;
}


 .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #101e2d;
    margin-bottom: 5px;
    display: block;
  }

  select {
    border: 1px solid #d0d5d9;
    border-radius: 4px;
    padding: 10px;
    background: #FAFBFD;
    width: 12%;
    padding-right : 5px;
  }
  .menu-icon{
      cursor: pointer;
  }

    @media (max-width: 576px) {
      padding  : 0 10px;
      .career-heading{
        flex-direction: column;
        align-items: flex-start;
        margin-bottom : 20px;
      }

      select{
        width : 50%;
      }
      .category-jobs{
        flex-direction : column;
      }
    }
`;
