import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";

import { ReactComponent as Time } from "assets/images/icons/Vector(19).svg";
import { getAvailability, getWeekSchedule } from "api/calendar";
import LoadingSpinner from "components/reusables/LoadingSpinner";
import Box from "./Box";

import { WeekViewWrapper } from "./styles/styled.weeview";

let dateAndTimeArray = (currentWeek) => {
  let currentTimeCount = 10;
  return new Array(9).fill(9).map(() => {
    currentTimeCount++;
    return currentWeek?.map((weekDay) => {
      return {
        time: `${currentTimeCount - 1}:00`,
        weekDay,
      };
    });
  });
};
const WeekView = ({ currentWeek }) => {
  const firstDate = dayjs(currentWeek[0]).format("YYYY-MM-DD");
  const lastDate = dayjs(currentWeek[6]).format("YYYY-MM-DD");
  const [dateAndTime, setDateAndTime] = useState(dateAndTimeArray(currentWeek));
  const [schedules, setSchedules] = useState([]);
  const { isLoading } = useQuery(
    [
      "get-week-schedule",
      {
        firstDate,
        lastDate,
      },
    ],
    getWeekSchedule,
    {
      onSuccess: (res) => {
        setSchedules(res?.data);
      },
    }
  );

  const { isLoading: availabilityLoading, data } = useQuery(
    ["get-availability"],
    getAvailability
  );

  useEffect(() => {
    setDateAndTime(dateAndTimeArray(currentWeek));
  }, [currentWeek]);

  if (isLoading || availabilityLoading) {
    return <LoadingSpinner />;
  }

  // console.log(currentWeek, data);
  const getActive = (dateObj) => {
    const date = dayjs(dateObj?.weekDay).format("YYYY-MM-DD");
    let event = schedules?.find(
      (item) => item.meetingDate === date && item.meetingTime === dateObj?.time
    );
    const item = {};
    if (event !== undefined) {
      item.active = true;
    } else {
      item.active = false;
    }
    return item;
  };

  const getIsFixed = (dateObj) => {
    const date = dayjs(dateObj?.weekDay).format("YYYY-MM-DD");
    let event = data.data?.find(
      (item) => item.date === date && item.time.includes(dateObj?.time)
    );

    const item = {};
    if (event !== undefined) {
      item.active = true;
    } else {
      item.active = false;
    }
    return item;
  };
  return (
    <WeekViewWrapper>
      <table className="mb-2">
        <thead>
          <tr className="thead-row">
            <th className="time">
              <Time />
            </th>
            {currentWeek?.map((day, i) => (
              <th key={i} scope="col" className="weekdays-header">
                <span className="d-block month">
                  {dayjs(day).format("ddd")}
                </span>
                <span className="d-block day">
                  {dayjs(day).format("MMM DD")}
                </span>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <table>
        <tbody>
          {dateAndTime?.map((row, i) => (
            <tr key={i} className="thead-row">
              <td className="time">{i + 10}:00</td>
              {row.map((day) => (
                <td className="weekdays-body">
                  <Box
                    isBooked={getActive(day)?.active}
                    isFixed={getIsFixed(day)?.active}
                    day={day}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </WeekViewWrapper>
  );
};

export default WeekView;
