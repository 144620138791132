import styledComponents from "styled-components";

export const UpcomingScheduleWrapper = styledComponents.div`
margin-top : 41px;
h2{
  font-weight: 600;
font-size: 14px;
line-height: 157%;
color: #666666;
}
`;
